import React from "react";
import { Box, Grid, GridItem, Text, useBreakpointValue } from "@chakra-ui/react";
import SiminnLogo from "../../Logo/SiminnLogo";


function MessageHandler({ title, description1, description2, highlightedWords }) {
    const isMobile = useBreakpointValue({ base: true, md: false });

    const highlightText = (text, words) => {
        if (!words || words.length === 0) {
          return text;
        }
    
        const regex = new RegExp(`(${words.join('|')})`, 'gi');
        const parts = text.split(regex);
    
        return parts.map((part, index) =>
          words.includes(part) ? (
            <Text as="span" key={index} fontWeight="700">
              {part}
            </Text>
          ) : (
            part
          )
        );
      };

  return (
    <Grid templateColumns={`repeat(${isMobile ? 4 : 12}, 1fr)`} gap={4} p={5}>
      <GridItem colSpan={isMobile ? 1 : 2} height="fit-content" pt="40px">
        <SiminnLogo/>
      </GridItem>
      <GridItem colStart={isMobile ? 1 : 4} colSpan={isMobile ? 4 : 6} textAlign="center" pt={isMobile ? "20vh" : "35vh"}>
        <Box>
          <Text variant={isMobile ? "introHeaderMobile" : "introHeader"} margin={0}>{title}</Text>
          <Text variant="introDesc" pt={isMobile ? "33px" : "24px"} pb={isMobile ? "32px" : "32px"} margin={0}>{description1}</Text>
          <Text variant="introDesc" margin={0}>{highlightText(description2, highlightedWords)}</Text>
        </Box>
      </GridItem>
    </Grid>
  );
}

export default MessageHandler;
