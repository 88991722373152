import React from 'react';
import { Grid, GridItem, Box, Text, useBreakpointValue } from '@chakra-ui/react'; // Import relevant components
import PropTypes from 'prop-types';
import SiminnLogo from "../../Logo/SiminnLogo";


const IntroOffer = ({ data }) => {

    IntroOffer.propTypes = {
        data: PropTypes.shape({
            name: PropTypes.string.isRequired,
            kennitala: PropTypes.string.isRequired
        }),
    };

    const isMobile = useBreakpointValue({ base: true, md: false });

    return (
        <React.Fragment>
            <Grid templateColumns={`repeat(${isMobile ? 4 : 12}, 1fr)`} gap={4} p={5}>
                <GridItem colSpan={isMobile ? 1 : 2} height="fit-content">
                    <SiminnLogo/>
                </GridItem>
                <GridItem colStart={isMobile ? 1 : 2} colSpan={isMobile ? 4 : 5} pt="44px">
                    <Box>
                        <Text variant="introHeader" mb='24px'>
                            Hæ {data.name.split(" ")[0]},
                        </Text>
                        <Text mb={6} variant="introDesc">
                            Hér er tilboðið þitt. Farðu vel yfir allar upplýsingar og ef allt er eins og það á að vera þá getur þú samþykkt tilboðið neðst á síðunni.
                        </Text>
                    </Box>
                </GridItem>
                <GridItem colEnd={isMobile ? -1 : 12} colSpan={isMobile ? 4 : 2} pt={isMobile ? "40px" : "124px"}>
                    <Text mb={0} variant="introDesc" align={isMobile ? "left" : "right"} w={isMobile ? "100%" : ""}>
                        {data.name}
                    </Text>
                    <Text variant="introDesc" align={isMobile ? "left" : "right"}>
                        {data.kennitala}
                    </Text>
                </GridItem>
            </Grid>
        </React.Fragment>
    );
};

export default IntroOffer;