// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textiError {
    margin-top: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
    color: var(--gray-darkest);
    font-size: 22px;
    line-height: 28px;
    font-weight: 300;
    text-align: center;
}

.textiError2 {
    margin-top: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
    color: var(--gray-darkest);
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;
    text-align: center;
}

@media (max-width: 479px) {
    .textiError {
        width: 90%;
        font-size: 18px;
    }

    .textiError2 {
        width: 90%;
        font-size: 15px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/utgardur/Error/Error.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;IACnB,0BAA0B;IAC1B,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;IACnB,0BAA0B;IAC1B,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI;QACI,UAAU;QACV,eAAe;IACnB;;IAEA;QACI,UAAU;QACV,eAAe;IACnB;AACJ","sourcesContent":[".textiError {\n    margin-top: 10px;\n    padding-top: 0px;\n    padding-bottom: 0px;\n    color: var(--gray-darkest);\n    font-size: 22px;\n    line-height: 28px;\n    font-weight: 300;\n    text-align: center;\n}\n\n.textiError2 {\n    margin-top: 10px;\n    padding-top: 0px;\n    padding-bottom: 0px;\n    color: var(--gray-darkest);\n    font-size: 18px;\n    line-height: 28px;\n    font-weight: 300;\n    text-align: center;\n}\n\n@media (max-width: 479px) {\n    .textiError {\n        width: 90%;\n        font-size: 18px;\n    }\n\n    .textiError2 {\n        width: 90%;\n        font-size: 15px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
