import React from 'react';
import PropTypes from 'prop-types';
import s from './Icon.css';
import SiminnLogo from "../Logo/SiminnLogo";

const icons = {
    clock: '\ue056',
    package: '\ue055',
    signal_tower: '\ue054',
    shopping_cart_empty: '\ue053',
    signal: '\ue052',
    location_si: '\ue051',
    pc_light_plus: '\ue050',
    pc_light_plus_plus: '\ue049',
    pc_light: '\ue048',
    opportunity: '\ue047',
    location: '\ue045',
    document: '\ue044',
    faces: '\ue043',
    download: '\ue042',
    chevron_down: '\ue041',
    minus: '\ue040',
    shopping_cart: '\ue039',
    windows: '\ue038',
    android: '\ue037',
    apple: '\ue036',
    mac_os: '\ue035',
    gear: '\ue034',
    infinity_solo: '\ue033',
    plus_thick: '\ue032',
    arrow_left: '\ue031',
    pc_play: '\ue030',
    phone_mobile: '\ue029',
    world: '\ue028',
    plus: '\ue027',
    phone_big: '\ue026',
    gears: '\ue025',
    mail: '\ue024',
    phone_signal: '\ue023',
    sim_card: '\ue022',
    alert: '\ue021',
    ok: '\ue020',
    cross: '\ue019',
    search: '\ue018',
    phone: '\ue017',
    person: '\ue016',
    arrow_down: '\ue015',
    arrow_right: '\ue014',
    comments: '\ue001',
    down_arrow_filled: '\ue002',
    face: '\ue003',
    facebook: '\ue004',
    globe: '\ue005',
    house: '\ue006',
    infinity: '\ue007',
    internet: '\ue008',
    logo: '\ue009',
    pc: '\ue90b',
    speedometer: '\ue010',
    twitter: '\ue012',
    youtube: '\ue013',
};

class Icon extends React.Component {
    static propTypes = {
        icon: PropTypes.string.isRequired,
    };

    render() {
        return this.props.icon === 'logo' ? <i className="root">{icons[this.props.icon]}</i> : <SiminnLogo/>;
    }
}

export default (Icon);
