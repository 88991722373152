import React from 'react';
import { Grid, GridItem, Box, Text, useBreakpointValue, Link, Divider } from '@chakra-ui/react'; // Import relevant components
import PropTypes from 'prop-types';


const DiscountSentencesAndInfo = ({ locale, showInfoPromotionSentence1, showInfoPromotionSentence2, installationCost, dataDescription }) => {

    DiscountSentencesAndInfo.propTypes = {
        data: PropTypes.shape({
            locale: PropTypes.any.isRequired,
            showInfoPromotionSentence1: PropTypes.bool,
            showInfoPromotionSentence2: PropTypes.bool,
            installationCost: PropTypes.any
        }),
    };

    const isMobile = useBreakpointValue({ base: true, md: false });

    return (
        <React.Fragment>
            <Grid templateColumns={`repeat(${isMobile ? 4 : 12}, 1fr)`} gap={4} p={5}>
                <GridItem colStart={isMobile ? 1 : 2} colSpan={isMobile ? 4 : 7}>
                    {installationCost}
                </GridItem>
                <GridItem colStart={isMobile ? 1 : 2} colSpan={isMobile ? 4 : 7}>
                    {dataDescription}
                </GridItem>
                {
                    showInfoPromotionSentence1 && (
                        <GridItem colStart={isMobile ? 1 : 2} colSpan={isMobile ? 4 : 7}>
                            <Box>
                                <Text variant="discountMessages">* {locale.infoPromotion1}</Text>
                            </Box>
                        </GridItem>
                    )
                }
                {
                    showInfoPromotionSentence2 && (
                        <GridItem colStart={isMobile ? 1 : 2} colSpan={isMobile ? 4 : 7}>
                            <Box>
                                <Text variant="discountMessages">** {locale.infoPromotion2}</Text>
                            </Box>
                        </GridItem>
                    )
                }
                <GridItem colStart={isMobile ? 1 : 2} colSpan={isMobile ? 4 : 7} pt='15,4px'>
                    <Box>
                        <Text variant="terms">
                            {locale.lawyerTerms} <Link color='#00007C' fontSize="16px" fontWeight="500" lineHeight="22px" href='https://www.siminn.is' isExternal> siminn.is</Link>.
                        </Text>
                        <Text variant="terms">
                            <Link color='#00007C' fontSize="16px" fontWeight="500" lineHeight="22px" href='https://www.siminn.is/verdskrar/simi' isExternal>Verðskrá</Link>, <Link color='#00007C' fontSize="16px" fontWeight="500" lineHeight="22px" href='https://www.siminn.is/skilmalar' isExternal>skilmála og stefnur</Link> Símans má einnig nálgast á <Link color='#00007C' fontSize="16px" fontWeight="500" lineHeight="22px" href='https://www.siminn.is' isExternal>siminn.is</Link>.
                        </Text>
                    </Box>
                    <Box>
                        <Text variant="terms">
                            {locale.terms} <Link color='#00007C' fontSize="16px" fontWeight="500" lineHeight="22px" href='https://www.siminn.is/verdskrar/reikningar' isExternal>verðskrá Símans.</Link>
                        </Text>
                    </Box>
                    <Box>
                        <Text variant="terms">
                            <Link color='#00007C' fontSize="16px" fontWeight="500" lineHeight="22px" href="https://www.siminn.is/" isExternal>Spjallmennið Sísí</Link> {locale.infoSisi}
                            <br />
                            {locale.extraInfo}
                            <br />
                            {locale.infoGeneral} <Link color='#00007C' fontSize="16px" fontWeight="500" lineHeight="22px" href="https://thjonustuvefur.siminn.is/" isExternal>siminn.is/minarsidur</Link>{locale.infoGeneral2}
                        </Text>
                    </Box>
                </GridItem>
                <GridItem colStart={isMobile ? 1 : 2} colSpan={isMobile ? 4 : 7}>
                    <Divider orientation="horizontal" />
                </GridItem>
            </Grid>
        </React.Fragment>
    );
};

export default DiscountSentencesAndInfo;




