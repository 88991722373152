import React from "react";
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, Box, Grid, Text, Stack, useBreakpointValue } from "@chakra-ui/react";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { calculateOriginalPrice, calculateTotalPrice, checkIfServiceNumberExistsAndHas7Digits, getTheSumUpOfOriginalPrice, getTheSumUpOfTotalPrice, filterProducts, displayDiscountOrNot, checkDiscountInChildren} from "../../../utils/utils";



function RecursiveAccordion({ products, isOwner, CPQv2, isTopLevel = true, actionIgnore }) {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const condition = product => {
    return !actionIgnore.includes(product.action);
  };
  const csCondition = product => product.isPayedByPayer && product.isPayedByPayer === "true";
  
  const discountConditionEqual = discount => discount.discountDuration === "4.0";
  const discountConditionDifferent = discount => discount.discountDuration !== "4.0";

  const filteredProducts = filterProducts(products, !isOwner ? [condition, csCondition] : [condition]);
  
  
  return (
    <Accordion allowMultiple>
      {filteredProducts.map((product, index) => (
        <AccordionItem key={index}>
          {({ isExpanded }) => (
            <>
              <h2 className="no-margin-top-bottom">
                <AccordionButton p={0} fontWeight={isTopLevel ? 700 : 400} pointerEvents={product.products && product.products.length > 0 ? "default" : "none"}>
                  <Grid templateColumns="1fr 1fr 1fr 20px" alignItems="center" w="100%" p={0}>
                    <Box flex="1" textAlign="left">
                      <Text variant={isMobile ? "pricesMobileChildMainProduct" : "pricesChildMainProduct"} fontWeight={isTopLevel ? 700 : 400}>
                        {checkIfServiceNumberExistsAndHas7Digits(product)}
                      </Text>
                    </Box>
                    <Box flex="1" textAlign="right">
                      <Text variant={isMobile ? "pricesMobileChildMainProduct" : "pricesChildMainProduct"} fontWeight={isTopLevel ? 700 : 400}>
                        {checkDiscountInChildren(product, discountConditionDifferent, discountConditionEqual)} {getTheSumUpOfOriginalPrice(product).toLocaleString('de-DE')} kr.
                      </Text>
                    </Box>
                    <Box flex="1" textAlign="right" mr={isMobile ? "2%" : "20%"}>
                      <Text variant={isMobile ? "pricesMobileChildMainProduct" : "pricesChildMainProduct"} fontWeight={isTopLevel ? 700 : 400}>
                        {getTheSumUpOfTotalPrice(product, isOwner, CPQv2).toLocaleString('de-DE')} kr.
                      </Text>
                    </Box>
                    <Box>
                      {product.products && product.products.length > 0 && (
                        isExpanded ? (
                          <MinusIcon fontSize="15px" />
                        ) : (
                          <AddIcon fontSize="15px" />
                        )
                      )}
                    </Box>
                  </Grid>
                </AccordionButton>
              </h2>
              {product.products && product.products.length > 0 && (
                  <AccordionPanel p={0} pl={isMobile ? 0 : 4}>
                    {calculateTotalPrice(product, isOwner, CPQv2) !== 0 && (
                  <Grid templateColumns="1fr 1fr 1fr 20px" p={0}>
                    <Box>
                      <Text variant={isMobile ? "pricesMobileChild" : "pricesChild"}>
                        {checkIfServiceNumberExistsAndHas7Digits(product)}
                      </Text>
                    </Box>
                    <Box textAlign="right">
                        <Text variant={isMobile ? "pricesMobileChild" : "pricesChild"}>
                        {displayDiscountOrNot(product)} {calculateOriginalPrice(product).toLocaleString('de-DE')} kr.
                      </Text>
                    </Box>
                    <Box textAlign="right" mr={isMobile ? "2%" : "20%"}>
                      <Text variant={isMobile ? "pricesMobileChild" : "pricesChild"}>
                        {calculateTotalPrice(product, isOwner, CPQv2).toLocaleString('de-DE')} kr.
                      </Text>
                    </Box>
                  </Grid>)}
                  <Stack mt={4} p={0}>
                    <RecursiveAccordion products={product.products} isOwner={isOwner} CPQv2={CPQv2} isTopLevel={false} actionIgnore={actionIgnore} />
                  </Stack>
                </AccordionPanel>
                
                
              )}
            </>
          )}
        </AccordionItem>
      ))}
    </Accordion>
  );
}

export default RecursiveAccordion;
