import React from 'react';
import { ThemeProvider, Link, Text, Button, Box, Container } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import './marketDesign.css';
import './design.css';
import '../../css/core.css';
import Message from '../Message/Message'
import { endsWithXAndNumber, getCustomAttributeValue } from "../../../utils/utils";
import customTheme from "../../../themes/customTheme";
import IntroOffer from '../IntroOffer/IntroOffer';
import PricesOffer from '../PricesOffer.js/PricesOffer';
import HeaderAccordion from '../Accordion/HeaderAccordion';
import DiscountSentencesAndInfo from '../DiscountSentencesAndButtons/DiscountSentencesAndInfo';
import AcceptOrRejectOffer from '../DiscountSentencesAndButtons/AcceptOrRejectOffer';
import MessageHandler from '../Message/MessageHandler';

require('es6-promise').polyfill();
require('isomorphic-fetch');

class QuoteLayout extends React.Component {

    static propTypes = {
        locale: PropTypes.object,
        data: PropTypes.objectOf(PropTypes.any)
    };

    constructor(props) {
        super(props);
        props.data.products = props.data.products.sort(this.rowSort);
        this.getDescription = this.getDescription.bind(this);
        let descriptionMap = new Map();
        let discountMap = new Map();
        let hasDiscount = false;
        let showInfoPromotionSentence1 = false;
        let showInfoPromotionSentence2 = false;
        let showServiceNumber = false;
        if (props.data.CPQv2 === "true") {
            hasDiscount = this.checkIfOfferHasDiscounts(props.data.products);
            showServiceNumber = this.checkIfOfferHasServiceIdentifier(props.data.products);
            showInfoPromotionSentence1 = hasDiscount && this.checkInfoPromotionSentences(props.data.products, "4.0", false);
            showInfoPromotionSentence2 = hasDiscount && this.checkInfoPromotionSentences(props.data.products, "4.0", true);
        } else {
            hasDiscount = props.data.products.some(product => (product.discounts !== undefined && product.discounts.length > 0) || (product.products !== undefined && product.products.some(subProduct => subProduct.discounts !== undefined && subProduct.discounts.length > 0)));
        }
        this.state = {
            isOwner: !this.props.cs,
            descriptions: this.getDescription(descriptionMap, props.data.products),
            discountDescriptions: this.getDiscountDescriptions(discountMap, props.data.products),
            canUseInfo: false,
            canConfirm: false,
            canDecline: true,
            confirmed: false,
            declined: false,
            confirmText: this.props.locale.confirmQuote,
            declineText: this.props.locale.declineQuote,
            submitError: false,
            hasDiscount: hasDiscount,
            showServiceNumber: showServiceNumber,
            submitting: false,
            CPQv2: props.data.CPQv2 === "true",
            rejecting: false,
            accepting: false,
            hasChargeSplit: false,
            showInfoPromotionSentence1: showInfoPromotionSentence1,
            showInfoPromotionSentence2: showInfoPromotionSentence2
        };

        this.confirmTerms = this.confirmTerms.bind(this);
        this.confirmInfoUse = this.confirmInfoUse.bind(this);
        this.confirmQuote = this.confirmQuote.bind(this);
        this.updatePendingText = this.updatePendingText.bind(this)

    }


    //Check if the product has a serviceIdentifier
    checkIfOfferHasServiceIdentifier(products) {
        let result = false;
        const traverse = (item) => {
            item.products.forEach(function (product) {
                if (product.serviceIdentifier && product.serviceIdentifier.length > 0) {
                    result = true;
                }
                if (product.products) {
                    traverse(product);
                }
            });
        };

        products.forEach(function (product) {
            if (product.serviceIdentifier && product.serviceIdentifier.length > 0) {
                result = true;
            }

            if (product.products) {
                traverse(product);
            }
        });
        return result;
    };
    //Check if product hasDiscount
    checkIfOfferHasDiscounts(products) {
        let result = false;
        const traverse = (item) => {
            item.products.forEach(function (product) {
                if (product.discounts && product.discounts.length > 0) {
                    result = true;
                }
                if (product.products) {
                    traverse(product);
                }
            });
        };

        products.forEach(function (product) {
            if (product.discounts && product.discounts.length > 0) {
                result = true;
            }

            if (product.products) {
                traverse(product);
            }
        });
        return result;
    };

    //Check if the offer has sentences for discounts
    checkInfoPromotionSentences(products, duration, EqualTo4) {
        let result = false;
        function checkDuration(product) {
            if (EqualTo4 === true) {
                if (product.discounts && product.discounts.length > 0 && product.discounts.some(discount => discount.discountDuration === duration)) {
                    result = true;
                }
            } else {
                if (product.discounts && product.discounts.length > 0 && product.discounts.some(discount => discount.discountDuration !== duration)) {
                    result = true;
                }
            }
        };
        const traverse = (item) => {
            item.products.forEach(function (product) {
                checkDuration(product);
                if (product.products) {
                    traverse(product);
                }
            });
        };

        products.forEach(function (product) {
            checkDuration(product);

            if (product.products) {
                traverse(product);
            }
        });
        return result;
    };

    confirmTerms() {
        this.setState({ canConfirm: !this.state.canConfirm })
    }

    confirmInfoUse() {
        this.setState({ canUseInfo: !this.state.canUseInfo })
    }

    getDescription(descriptionMap, products) {
        let self = this;
        products.forEach(function (product) {
            if (!self.props.cs) {
                descriptionMap = self.addDescription(descriptionMap, product, 'false');
            }
            else if (self.props.cs) {
                descriptionMap = self.addDescription(descriptionMap, product, 'true');
            }
        });
        return descriptionMap;
    }

    addDescription(descriptionMap, product, payerFlag) {
        let self = this;
        if ((product.action !== 'delete' && product.action !== 'CEASE') && product.description !== undefined && !descriptionMap.has(product.name) && product.isPayedByPayer === payerFlag) {
            descriptionMap.set(product.name, product.description);
        }
        if (product.products !== undefined && product.products.length > 0) {
            descriptionMap = new Map([...self.getDescription(descriptionMap, product.products), ...descriptionMap]);
        }
        if (product.retiredProductInfo) {
            product.name = product.retiredProductInfo;
        }
        return descriptionMap;
    }

    getDiscountDescriptions(descriptionMap, products) {
        for (const element of products) {
            if (element.discounts !== undefined) {
                element.discounts.forEach(function (discount) {
                    if (!descriptionMap.has(discount.name)) {
                        descriptionMap.set(discount.name, discount.description);
                    }
                });
            }
            if (element.discount !== undefined) {
                if (!descriptionMap.has(element.discount.name)) {
                    descriptionMap.set(element.discount.name, element.discount.description);
                }
            }
            if (element.products !== undefined) {
                this.getDiscountDescriptions(descriptionMap, element.products)
            }
        }
        return descriptionMap;
    }

    updatePendingText() {
        let buttonText;
        if (this.state.rejecting) {
            buttonText = this.state.declineText;
        } else {
            buttonText = this.state.confirmText;
        }
        if (buttonText.split('.').length > 4) {
            buttonText = this.props.locale.pendingText;
        }
        buttonText += '.';
        if (this.state.rejecting) {
            this.setState({ declineText: buttonText });
        } else {
            this.setState({ confirmText: buttonText });
        }
    }

    confirmQuote(confirm) {
        let interval;
        if (this.state.submitting || (confirm && !this.state.canConfirm))
            return;
        if (confirm) {
            this.setState({ confirmText: this.props.locale.pendingText, submitting: true, accepting: true });
            interval = setInterval(this.updatePendingText, 500);
        } else {
            this.setState({ declineText: this.props.locale.pendingText, submitting: true, rejecting: true });
            interval = setInterval(this.updatePendingText, 500);
        }
        let payLoad = { accepted: confirm, id: this.props.data.quoteId, acceptMarketing: this.state.canUseInfo, performedBy: this.state.isOwner ? 'Owner' : 'Payer' };
        let self = this;
        const query = new URLSearchParams(window.location.search);
        fetch(process.env.REACT_APP_BACKEND_ROUTE.concat("tilbod/patchOffer/" + query.get("id")), {
            headers: { 'Content-Type': 'application/json' },
            method: 'PATCH',
            body: JSON.stringify(payLoad),
        }).then(function (response) {
            clearInterval(interval);
            if (!response.ok) {
                throw Error(response.statusText);
            }
            if (response.status >= 200 && response.status < 300) {
                if (confirm) {
                    self.setState({ confirmed: true });
                } else {
                    self.setState({ declined: true });
                }
            }
        }).catch(function (error) {
            clearInterval(interval);
            self.setState({ data: { message: error.message }, submitError: true });
        })
    }


    rowSort(a, b) {
        if (a.action.localeCompare(b.action) === 0) {
            if (a.action === "nochange" || a.action === 'NO_ACTION') {
                if (a.products) {
                    if (a.products.some(x => (x.action !== "nochange" && x.action !== 'NO_ACTION'))) {
                        return 1;
                    }
                    if (b.products) {
                        if (b.products.some(x => (x.action !== "nochange" && x.action !== 'NO_ACTION'))) {
                            return -1;
                        }
                    }
                }
                if (b.products) {
                    if (b.products.some(x => (x.action !== "nochange" && x.action !== 'NO_ACTION'))) {
                        return -1;
                    }
                    if (a.products) {
                        if (a.products.some(x => (x.action !== "nochange" && x.action !== 'NO_ACTION'))) {
                            return 1;
                        }
                    }
                }
            }
            return 0;
        } else if (a.action === "nochange" || a.action === 'NO_ACTION') {
            return -1;
        } else if (b.action === "nochange" || b.action === 'NO_ACTION') {
            return 1;
        } else {
            return 0;
        }
    }

    getPrice(monthlyPriceWithDiscount, monthlyPriceWithDiscountPayer, priceAfter, priceAfterPayer, sumPrice, sumPricePayer, isMonthlyPay) {
        let price;

        if (this.state.hasChargeSplit) {
            if (monthlyPriceWithDiscount) {
                price = this.state.isOwner ? monthlyPriceWithDiscount : monthlyPriceWithDiscountPayer;
            } else {
                if (this.state.CPQv2 && isMonthlyPay) {
                    price = this.state.isOwner ? priceAfter : priceAfterPayer;
                } else
                    price = this.state.isOwner ? sumPrice : sumPricePayer;
            }
        } else {
            price = monthlyPriceWithDiscount ? monthlyPriceWithDiscount : (this.state.CPQv2 && isMonthlyPay ? priceAfter : sumPrice);
        }
        return price;
    }



    renderRow(product, hasDiscount, showServiceNumber, showOriginalPrice) {
        let productHasDiscount1 = product.discounts && product.discounts.length > 0 && product.discounts.some(discount => discount.discountDuration !== "4.0");
        let productHasDiscount2 = product.discounts && product.discounts.length > 0 && product.discounts.some(discount => discount.discountDuration === "4.0");
        let priceProduct = "";
        let isMainProduct = product.isMainProduct;
        //Check the quantity of the products and display it properly
        if (product.quantity && parseInt(product.quantity) > 1 && !endsWithXAndNumber(product.name)) {
            product.name = product.name + " x" + parseInt(product.quantity).toString();
            product.subTotal = product.subTotal * parseInt(product.quantity);
        }
        if (product.attributes && product.attributes !== "") {
            priceProduct = getCustomAttributeValue(JSON.parse(product.attributes), "PriceBeforeChargeSplit");
        }
        let row = [];

        let price = product.isPayedByPayer === 'true' ?
            this.state.isOwner ? "0" : product.listTotal !== "0.0" ? product.listTotal : priceProduct === "" ? product.totalPrice : priceProduct
            :
            showOriginalPrice && product.originalPrice ? product.originalPrice : product.subTotal;
        row.push(<div
            className={`tilbodsgerdtextgrid ${isMainProduct ? 'main-product' : 'sub-product'}`}>{product.name}</div>);
        if (showServiceNumber) {
            row.push(<div
                className="tilbodsgerdtextgrid">{product.serviceIdentifier ? product.serviceIdentifier : '.'}</div>);
        }
        if (hasDiscount) {
            row.push(<div
                className="tilbodsgerdtextgrid">{Math.round(Math.abs(Number(product.totalPrice))).toLocaleString('de-DE')} kr. {productHasDiscount1 ? "*" : productHasDiscount2 ? "**" : ""}</div>);
        }
        row.push(<div
            className="tilbodsgerdtextgrid">{Math.round(Math.abs(Number(price))).toLocaleString('de-DE')} kr.</div>);
        return row;
    }

    renderProductAddress(product) {
        if (product.attributes && product.attributes !== "") {
            let customAttributes = JSON.parse(product.attributes);
            let newLineStreet = getCustomAttributeValue(customAttributes, "ConnectionStreetName");
            let newLineCity = getCustomAttributeValue(customAttributes, "ConnectionCity");
            let newLineHouseNumber = getCustomAttributeValue(customAttributes, "ConnectionStreetNumber");
            let newLineZipCode = getCustomAttributeValue(customAttributes, "ConnectionPostCode");
            let newLineApartment = getCustomAttributeValue(customAttributes, "ConnectionApartmentNumber");
            return <div
                className="tilbodsgerdtextgrid subitemonecolumgrid">{newLineApartment ? `${newLineStreet} ${newLineHouseNumber} - ${newLineApartment}` : `${newLineStreet} ${newLineHouseNumber}`}<br />{newLineZipCode} {newLineCity}
            </div>
        }
    }

    getInstallationCostProducts(product) {
        let customAttributes = JSON.parse(product.attributes);
        if (getCustomAttributeValue(customAttributes, "RejectedInstallationCost") === 'true')
            this.rejectedInstallationCost = true;
        if (product.isPayedByPayer && product.isPayedByPayer === "true") {
            this.state.hasChargeSplit = true;
            if (this.state.isOwner) {
                product.totalPrice = '0.0';
                product.subTotal = '0.0';
                product.originalPrice = '0.0';
                return getCustomAttributeValue(customAttributes, "OneTimeCost");
            } else {  //is payer
                if (getCustomAttributeValue(customAttributes, "PriceBeforeChargeSplit") !== "") {
                    product.totalPrice = getCustomAttributeValue(customAttributes, "PriceBeforeChargeSplit");
                }
                return getCustomAttributeValue(customAttributes, "OneTimeCost");
            }
        } else if (this.state.isOwner) {  //payer only sees charge splits products
            return getCustomAttributeValue(customAttributes, "OneTimeCost");
        }
        return false;
    }

    renderOneTimeProducts(data) {
        let oneTimeProd = [];
        let that = this;

        const traverse = (item) => {
            item.products.forEach(function (product) {
                if (product.attributes && product.attributes !== "") {
                    if (that.getInstallationCostProducts(product) === 'true') {
                        oneTimeProd.push(product);
                    }
                }
                if (product.products) {
                    traverse(product);
                }
            });
        };

        data.products.forEach(function (product) {
            if (product.attributes && product.attributes !== "") {
                if (that.getInstallationCostProducts(product) === 'true') {
                    oneTimeProd.push(product);
                }
            }
            if (product.products) {
                traverse(product);
            }
        });
        return oneTimeProd;
    }

    getProduct(actionIgnore, product) {
        if (!actionIgnore.includes(product.action)) {
            //This is for the quick fix and will be changed completely later DIGITAL-1121
            if (product.rootId && product.rootId.length > 0) {
                product.isMainProduct = false;
            } else {
                product.isMainProduct = true;
            }
            //END This is for the quick fix and will be changed completely later DIGITAL-1121
            if (product.attributes && product.attributes !== "") {
                if (getCustomAttributeValue(JSON.parse(product.attributes), "OneTimeCost") !== "true") {
                    if (product.isPayedByPayer && product.isPayedByPayer === "true") {
                        this.state.hasChargeSplit = true;
                        if (this.state.isOwner) {
                            product.totalPrice = '0.0';
                            product.subTotal = '0.0';
                            product.originalPrice = '0.0';
                            return product;
                        } else {    //is payer
                            return product;
                        }
                    } else if (this.state.isOwner) {  //payer only sees charge splits products
                        return product;
                    }
                }
            } else if (this.state.isOwner) {
                return product;
            }
        }
        return false;
    }

    populateProducts(actionIgnore, data) {
        let relevantProducts = [];
        let prod;
        let that = this;
        const traverse = (item) => {
            item.products.forEach(function (product) {
                prod = that.getProduct(actionIgnore, product);
                if (prod)
                    relevantProducts.push(prod);
                if (product.products) {
                    traverse(product);
                }
            });
        };

        data.products.forEach(function (product) {
            prod = that.getProduct(actionIgnore, product);
            if (prod)
                relevantProducts.push(prod);
            if (product.products) {
                traverse(product);
            }
        })
        return relevantProducts;
    }

    getPricesGrid(data) {
        let promotionalPrice = this.getPrice(data.monthlyPriceWithDiscount, data.monthlyPriceWithDiscountPayer, data.priceAfter, data.priceAfterPayer, data.totalPrice, data.totalPricePayer, true);
        let afterPrice = this.getPrice(data.monthlyPrice, data.monthlyPricePayer, data.priceAfterWithoutDiscount, data.priceAfterWithoutDiscountPayer, data.subTotal, data.subTotalPayer, true);
        let todayPrice = this.getPrice(null, null, null, null, data.priceBefore, data.priceBeforePayer, false);
        let newPricesGrid = [todayPrice, promotionalPrice, afterPrice]

        return newPricesGrid;
    }



    renderGrid(actionIgnore, data, locale, headerMessage, showDiscount, isMonthlyPay, rootClass, gridClass, showOriginalPrice, showProductAddress) {
        let grProduct = "GR_Connection"
        let relevantProducts = this.populateProducts(actionIgnore, data);

        let columnClass = "twocolumngrid";
        //This is for the quick fix on teh design for DIGITAL-1121
        let numberOfDivsToAdd = 2;
        // END This is for the quick fix on teh design for DIGITAL-1121
        function checkActionOfProducts(products) {
            return products.some(product => (product.products && product.products.length > 0) && !actionIgnore.includes(product.action) ? checkActionOfProducts(product.products) : !actionIgnore.includes(product.action));
        };

        let showServiceNumber = this.state.showServiceNumber && checkActionOfProducts(relevantProducts);
        if (showDiscount && showServiceNumber) {
            columnClass = "fourcolumngrid";
            numberOfDivsToAdd = 4;
        } else if (showDiscount || showServiceNumber) {
            columnClass = "threecolumngrid";
            numberOfDivsToAdd = 3;
        }
        columnClass = columnClass + ' ' + gridClass;
        let rows = [];
        relevantProducts.forEach(function (product, index) {
            const nextIsMain = relevantProducts[index + 1]?.isMainProduct;
            if (product.discounts && product.discounts.length > 0 && this.state.CPQv2) {
                showOriginalPrice = true;
            }
            rows.push(this.renderRow(product, showDiscount, showServiceNumber, showOriginalPrice));
            const queryString = window.location.search;

            // DIGITAL-1121 This will check if the quote is CPQ and if it is then we create the space between main products else we don't
            const queryParams = new URLSearchParams(queryString);
            const paramValue = queryParams.get('id');
            const startsWithCPQ = paramValue ? paramValue.startsWith('CPQ') : false;
            if (startsWithCPQ) {
                if (nextIsMain && index !== relevantProducts.length - 1) {
                    for (let i = 0; i < numberOfDivsToAdd; i++) {
                        rows.push(<div className="tilbodsgerdtextgrid"> </div>);
                    }
                }
            }
            if (showProductAddress && product.productcode && product.productcode.toUpperCase() === grProduct.toUpperCase()) {
                rows.push(this.renderProductAddress(product))
            }
        }, this)
        let grid =
            <div className={rootClass}>
                <div className="pontuniditem">
                    <div className="tilbodsgerdheadingdesc">{headerMessage}</div>
                </div>
                <div className={"w-layout-grid " + columnClass}>
                    <div className="tilbodsgerddivcontent">
                        <div className="tilbodsgerdgridheading">Þjónusta</div>
                    </div>
                    {showServiceNumber
                        ?
                        <div className="tilbodsgerddivcontent">
                            <div className="tilbodsgerdgridheading">Númer</div>
                        </div>
                        : ''
                    }
                    {showDiscount
                        ?
                        <div className="tilbodsgerddivcontent">
                            <div className="tilbodsgerdgridheading">Kynningarverð</div>
                        </div>
                        : ''
                    }
                    <div className="tilbodsgerddivcontent">
                        <div className="tilbodsgerdgridheading">Mánaðarverð</div>
                    </div>
                    {rows}
                </div>
            </div>;

        let newGrid = <HeaderAccordion products={data.products} isOwner={this.state.isOwner} CPQv2={this.state.CPQv2} showDiscount={showDiscount} headerMessage={headerMessage} actionIgnore={actionIgnore} />
        return newGrid;
    }

    render() {
        const { locale, data } = this.props;
        let isOwner = this.state.isOwner;
        let descriptions = [];
        let discountDescriptions = [];
        let grids = [];
        let showBeforeGrid = this.state.CPQv2 && data.products.some(x => (x.action !== "new" && x.action !== "PROVIDE"));
        let afterGrid = this.renderGrid(['delete', 'CEASE'], data, locale, 'Þjónustan þín miðað við nýtt tilboð', this.state.hasDiscount, true, "tilbodsgerditem", "tilbodsgerdgrid", false);
        let offerGrid;
        if (showBeforeGrid) {
            offerGrid = this.renderGrid(['nochange', 'delete', 'NO_ACTION', 'CEASE', 'NO ACTION'], data, locale, "Tilboðið þitt", true, false, "tilbodsgerditem", "tilbodsgerdgrid-copy", false, true);
            grids.push(offerGrid);
            grids.push(<div className="tilbodbreaker" />);
        }
        grids.push(afterGrid);
        let priceGrid = this.getPricesGrid(data);
        let priceAndDateData = {
            creationDate: data.creationDate,
            expirationDate: data.expirationDate,
            todayPrice: priceGrid[0],
            promotionalPrice: priceGrid[1],
            afterPrice: priceGrid[2]
        };
        let acceptQuoteOrReject = <>
            <label className="w-checkbox formcheckbox">
                <input type="checkbox" id="skilmalar" data-name="" title="skilmalar"
                    onChange={this.confirmTerms}
                    aria-label="samþykkja skilmála"
                    className="tilbodcheckbox w-checkbox-input w-checkbox-input--inputType-custom regular-checkbox" />
                <Text variant="termsCheckbox">Ég hef lesið og samþykki skilmálana. <Link color='#00007C' fontSize="16px" fontWeight="500" lineHeight="20px" href="https://www.siminn.is/skilmalar/simi#skilmalar"
                    isExternal>Sjá nánar hér</Link></Text>
            </label><label className="w-checkbox formcheckbox">
                <input type="checkbox" id="skilabod" title="skilmalar" onClick={this.confirmInfoUse}
                    aria-label="samþykkja skilmála"
                    className="tilbodcheckbox w-checkbox-input w-checkbox-input--inputType-custom regular-checkbox" />

                <Text variant="termsCheckbox">Ég <Link href="https://static.siminn.is/Webflow/Skjol/skilmalar/Pers%C3%B3nuverndarstefnur/FRAE-10625_Samthykki_i_markadslegum_tilgangi.pdf"
                    color='#00007C' fontSize="16px" fontWeight="500" lineHeight="20px" isExternal>samþykki að fá tölvupósta í markaðslegum tilgangi</Link> frá Símanum.</Text>
            </label></>;

        let acceptButton = <Button colorScheme="darkblue" width="100%" height="48px" p="12px 24px" borderRadius="8px" background={!this.state.canConfirm ? "#E8E8E8" : "#00007C"} color={!this.state.canConfirm ? "#60676B" : "#FFFFFF"} fontSize="18px" lineHeight="27px" fontWeight="500" onClick={() => this.confirmQuote(true)} isDisabled={!this.state.canConfirm}>
            {this.state.confirmText}
        </Button>;

        let rejectButton = <Button colorScheme="red" width="100%" height="48px" p="12px 24px" borderRadius="8px" background="#BF1B1B" fontSize="18px" lineHeight="27px" fontWeight="500" onClick={() => this.confirmQuote(false)}>
            {this.state.declineText}
        </Button>;
        this.state.descriptions.forEach(function (value, key, map) {
            let elementStart = value.indexOf('<');
            if (elementStart > -1) {
                let elementEnd = value.lastIndexOf('>');
                let element = value.substr(elementStart, elementEnd - elementStart + 1);
                let elementDiv = <div style={{ display: "contents" }} dangerouslySetInnerHTML={{ __html: element }} />
                let textBeforeElement = value.split('<')[0];
                let textAfterElement = value.split('>').pop();
                descriptions.push(
                    <div className="tilbodsgerditemsummaryitem"><h3 className="heading-38">{key}<br /></h3>
                        <div className="text-block-450">{textBeforeElement}{elementDiv}{textAfterElement}<br /></div>
                    </div>)
            } else {
                descriptions.push(
                    <div className="tilbodsgerditemsummaryitem"><h3 className="heading-38">{key}<br /></h3>
                        <div className="text-block-450">{value}<br /></div>
                    </div>)
            }
        })
        this.state.discountDescriptions.forEach(function (value, key, map) {
            discountDescriptions.push(
                <div className="tilbodsgerditemsummaryitem"><h3 className="heading-38">{key}<br /></h3>
                    <div className="text-block-450">{value}<br /></div>
                </div>
            )
        })
        let installationCost = [];
        let dataDescription;
        if (data.description) {
            dataDescription = <Box>
                <Text variant="descriptionTitle" color="#00007C">
                    Skilaboð frá ráðgjafa
                </Text>
                <Text variant="descriptionText">
                    {data.description}
                </Text>
            </Box>
        }
        let oneTimeProdAux = this.renderOneTimeProducts(data);
        if (this.rejectedInstallationCost) {
            installationCost =
                <Box>
                    <Text variant="installationCostInfo">
                        Viðskiptavinur sækir/er með eigin búnað og tengir sjálfur 0 kr.
                    </Text>
                </Box>;
        }
        else if (oneTimeProdAux.length > 0) {
            oneTimeProdAux.forEach(function (product) {
                installationCost.push(
                    <Box>
                        <Text variant="installationCost" color="#00007C">
                            {product.name} {Math.round(Math.abs(Number(product.totalPrice))).toLocaleString('de-DE')} kr.
                        </Text>
                    </Box>
                );
            }, this)
        }

        if (this.state.confirmed) {
            return (<MessageHandler title={locale.confirmHeader} description1={locale.confirmText} description2={locale.signedSiminn} />);
        } else if (this.state.declined) {
            return (<MessageHandler title={locale.rejected.heading} description1={locale.rejected.body} description2={locale.rejected.message} />);
        } else if (this.state.submitError) {
            return (<MessageHandler title={data.message} description1={locale.errormessage.heading} description2={data.message}
                text2={locale.errormessage.message} />);
        } else
            return (
                <React.Fragment>
                    <Container maxWidth="1600px" centerContent>
                        <ThemeProvider theme={customTheme}>
                            <IntroOffer data={data.account} />
                            <PricesOffer data={priceAndDateData} />
                            {grids}
                            <DiscountSentencesAndInfo locale={locale} showInfoPromotionSentence1={this.state.showInfoPromotionSentence1} showInfoPromotionSentence2={this.state.showInfoPromotionSentence2} installationCost={installationCost} dataDescription={dataDescription} />
                            <AcceptOrRejectOffer acceptQuoteOrReject={acceptQuoteOrReject} acceptButton={acceptButton} rejectButton={rejectButton} />
                        </ThemeProvider>
                    </Container>
                </React.Fragment>
            )
    }
}

export default (QuoteLayout);
