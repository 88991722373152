import React from 'react';
import './Header.css';
import Icon from '../Icon';
import SiminnLogo from "../Logo/SiminnLogo";

const Header = () => {
    return (
        <header className="header">
            <a className="logo" href="https://www.siminn.is/">
                <SiminnLogo/>
            </a>
        </header>
    );
}

export default (Header);