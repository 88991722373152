// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    padding: 0 20px;
    font-weight: 200;
    display: flex;
}

.logo {
    margin: 20px 19px 20px 10px;
    font-size: 48px;
    line-height: 60px;
}

@media only screen and (max-width: 767px) {
    .logo {
        margin: 0 19px 0 10px;
        font-size: 24px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,2BAA2B;IAC3B,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI;QACI,qBAAqB;QACrB,eAAe;IACnB;AACJ","sourcesContent":[".header {\n    padding: 0 20px;\n    font-weight: 200;\n    display: flex;\n}\n\n.logo {\n    margin: 20px 19px 20px 10px;\n    font-size: 48px;\n    line-height: 60px;\n}\n\n@media only screen and (max-width: 767px) {\n    .logo {\n        margin: 0 19px 0 10px;\n        font-size: 24px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
