import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-dropdown-select';
import { Modal } from 'react-responsive-modal';
import NumberFormat from 'react-number-format';
import ReactLoading from 'react-loading';
import 'react-responsive-modal/styles.css';
import './Layout.css';

const optionsMonth = [
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
    { value: '04', label: '04' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
    { value: '07', label: '07' },
    { value: '08', label: '08' },
    { value: '09', label: '09' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' }
];

const optionsYear = [];

class Layout extends React.Component {
    static propTypes = {
        locale : PropTypes.objectOf(PropTypes.object).isRequired,
        data: PropTypes.objectOf(PropTypes.any).isRequired,
        token: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.getCardYear();
        this.state = {
            openSave: false,
            openError: false,
            showButton: true,
            selectedMonth: '',
            selectedYear: ''
        }
    }

    getCardnumber(num) {
        return num.match(/.{1,4}/g).join('-');
    }

    getCardYear() {
        var yearBegin = new Date().getFullYear();
        var yearEnd = yearBegin + 8;

        for(var i = yearBegin; i <= yearEnd; i++) {
            optionsYear.push({ value: i.toString().substr(-2), label: i.toString().substr(-2) });
        }
    }

    getSelectedMonth = (e) => {
        if(e.length === 1) {
            this.setState({
                selectedMonth: e[0].value
            });
        }
    };

    getSelectedYear = (e) => {
        if(e.length === 1) {
            this.setState({
                selectedYear: e[0].value
            });
        }
    };

    onOpenSaveModal = () => {
        this.setState({ openSave: true });
    };

    onCloseSaveModal = () => {
        window.location.reload();
        this.setState({ openSave: false });
    };

    onOpenErrorModal = () => {
        this.setState({ openError: true });
    };

    onCloseErrorModal = () => {
        this.setState({ openError: false });
    };

    createOrder(token, month, year) {
        this.setState({
            showButton: false
        });

        if(month === '' || year === '') {
            this.setState({
                showButton: true
            });

            alert(this.props.locale.errorMessage.messageAlert);

            return;
        }

        fetch(process.env.REACT_APP_BACKEND_ROUTE.concat("gildistimi/updateExpirydate"),{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                token: token,
                expirydate: month + year,
            })
        }).then(response => response.json())
          .then((data) => {
              if(data.UpdateExpirydateOutput.StandardOutput.success) {
                  this.setState({
                      showButton: true
                  });

                  this.onOpenSaveModal();
              } else {
                  throw new Error();
              }
          }).catch((error) => {
              this.setState({
                  showButton: true
              });

              this.onOpenErrorModal();
          });
    }

    render() {
        const { openSave, openError, showButton, selectedMonth, selectedYear } = this.state;
        const { locale, data, token } = this.props;
        return (
            <div>
                <div className="nuverandaskriftwrap">
                    <div className="div-block-57">
                        <div className="text-block-36">{locale.text.titleGreet}</div>
                        <div className="texti">
                            {locale.text.message}
                        </div>
                        <div className="texti2">
                            {data.GetInvoiceOutput.phonenumber}
                        </div>
                        <div className="texti">
                            {locale.text.messageExpire}
                        </div>
                    </div>
                </div>
                <div>
                    <div className="_2cols">
                        <div className="col">
                            <h1 className="heading-3">{locale.text.title}</h1>
                            <div className="hr"></div>
                            <div className="texti2">
                                <div className="text-block-40">
                                    {locale.text.cardNumber} <br/>
                                    {this.getCardnumber(data.GetInvoiceOutput.cardnumber)}
                                </div>
                                <div className="text-block-40">
                                    {locale.text.expireDate} <br/>
                                    {(data.GetInvoiceOutput.expirydate).toString().length === 3 ?
                                        <NumberFormat value={data.GetInvoiceOutput.expirydate} displayType={'text'} format="#/##" />
                                        :
                                        <NumberFormat value={data.GetInvoiceOutput.expirydate} displayType={'text'} format="##/##" />
                                    }
                                </div>
                            </div>
                            <div className="divLayout-2">
                                <div className="divLayout">
                                    <div className="texti3">
                                        {locale.text.month}
                                    </div>
                                    <Select
                                        placeholder="Veldu..."
                                        options={optionsMonth}
                                        value={selectedMonth}
                                        onChange={this.getSelectedMonth}
                                    />
                                    </div>
                                <div className="divLayout">
                                    <div className="texti3">
                                        {locale.text.year}
                                    </div>
                                    <Select
                                        placeholder="Veldu..."
                                        options={optionsYear}
                                        value={selectedYear}
                                        onChange={this.getSelectedYear}
                                    />
                                </div>
                            </div>
                            {showButton ?
                                <button className="kaupabtn w-button" onClick={() => this.createOrder(token, selectedMonth, selectedYear)}>{locale.text.button}</button>
                                :
                                <ReactLoading type={'bubbles'} color={'#03aef3'} height={'10%'} width={'10%'}/>
                            }
                            <Modal open={openSave} onClose={this.onCloseSaveModal} little>
                                <div className="logowrapper">
                                    <div className="stadfestingtopwrap">
                                        <img src="https://static.siminn.is/tilbod/logo.png" alt="" width="80" className="stadfestinglogo" rel="noopener noreferrer"/>
                                        <div className="stadfestingheader">{locale.modalText.titleSave}</div>
                                    </div>
                                </div>
                                <div className="stadfestingbottomwrap">
                                    <div className="stadfestingtext">
                                        <strong>
                                            {locale.modalText.saveMessage}
                                        </strong>
                                    </div>
                                </div>
                                <button className="innskrabtn w-button" onClick={this.onCloseSaveModal}>{locale.modalText.button}</button>
                            </Modal>
                            <Modal open={openError} onClose={this.onCloseErrorModal} little>
                                <div className="logowrapper">
                                    <div className="stadfestingtopwrap">
                                        <img src="https://static.siminn.is/tilbod/logo.png" alt="" width="80" className="stadfestinglogo" rel="noopener noreferrer"/>
                                        <div className="stadfestingheader">{locale.modalText.titleError}</div>
                                    </div>
                                </div>
                                <div className="stadfestingbottomwrap">
                                    <div className="stadfestingtext">
                                        <strong>
                                            {locale.modalText.message}
                                        </strong>
                                        <br/>
                                        Hafðu samband í Netspjalli á <a href="https://www.siminn.is/" target="_blank" className="insertlink" rel="noopener noreferrer">siminn.is</a> eða í síma 550 6000 til að uppfæra gildistímann og fá frekari upplýsingar.
                                    </div>
                                </div>
                                <button className="innskrabtn w-button" onClick={this.onCloseErrorModal}>{locale.modalText.button}</button>
                            </Modal>
                            <div className="texti">{locale.text.messageChangePayment} <a href="https://thjonustuvefur.siminn.is/MittFrelsi/stillingar/greidslukort" target="_blank" className="insertlink" rel="noopener noreferrer">hér.</a></div>
                        </div>
                        <div className="col">
                            <div className="note">{locale.text.messageNote}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default (Layout);
