import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import './DataLayout.css';

class DataLayout extends React.Component {
    static propTypes = {
        locale: PropTypes.objectOf(PropTypes.object).isRequired,
        data: PropTypes.objectOf(PropTypes.any).isRequired,
    };

    getSubscriptionName(sub) {
        return sub.split(/[-]? [0-9]* ?[A-Z]{2}$/);
    }

    render() {
        const { locale, data } = this.props;
        return (
            <div className="nuverandaskriftwrap">
                <div className="div-block-57">
                    <div className="text-block-36">{locale.dataLayout.currentSubscription}</div>
                    {data.subscription.monthlyCharge === 0 && data.subscription.includedDataGb === 0  && data.subscription.ratePlan !== 'GSGF0'?
                        ''
                        :
                        <div className="stadawrap">
                            <div className="statusgbleft">{this.getSubscriptionName(data.subscription.description)}</div>
                            {data.subscription.includedDataGb === -1 ?
                                <div className="stadatext2 stadatextCenter">{data.subscription.ratePlan === 'GNU12' ? 'Endalaus með Netinu GB' : 'Endalaus GB' }</div>
                                :
                                <div className="stadatext stadatextCenter">
                                    <NumberFormat value={data.subscription.includedDataGb} displayType={'text'}
                                                  decimalScale={2} thousandSeparator={'.'}
                                                  decimalSeparator={','}/> {locale.text.dataEnding}
                                </div>
                            }
                            {data.subscription.monthlyCharge === 0 ?
                                <div className="statusgbright"></div>
                                :
                                <div className="statusgbright"><NumberFormat value={data.subscription.monthlyCharge} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','}/> {locale.text.krEnding}</div>
                            }
                        </div>
                    }
                    {typeof(data.package) !== "undefined" ?
                        <div className="stadawrap">
                            <div className="statusgbleft">{this.getSubscriptionName(data.package.description)}</div>
                            {data.package.includedDataGb === -1 ?
                                <div className="stadatext2 stadatextCenter">{data.package.ratePlan === 'GNU12' ? 'Endalaus með Netinu GB' : 'Endalaus GB' }</div>
                                :
                                <div className="stadatext stadatextCenter"><NumberFormat
                                    value={data.package.includedDataGb} displayType={'text'} decimalScale={2}
                                    thousandSeparator={'.'} decimalSeparator={','}/> {locale.text.dataEnding}</div>
                            }
                            <div className="statusgbright"><NumberFormat value={data.package.monthlyCharge} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','}/> {locale.text.krEnding}</div>
                        </div>
                        :
                        ''
                    }
                    {data.has10x ?
                        <div className="stadawrap">
                            <div className="statusgbleft">{locale.dataLayout.currentDataWith10x}</div>
                            <div className="stadatext stadatextCenter"><NumberFormat value={typeof(data.package) !== "undefined" ? (data.package.includedDataGb + data.subscription.includedDataGb) * 10 : data.subscription.includedDataGb * 10} displayType={'text'} decimalScale={2} thousandSeparator={'.'} decimalSeparator={','}/> {locale.text.dataEnding}</div>

                            <div className="statusgbright"></div>
                        </div>
                        :
                        ''
                    }
                    <div className="stadawrap">
                        <div className="statusgbleft">{locale.dataLayout.currentRoamingData}</div>
                        <div className="stadatext stadatextCenter"><NumberFormat value={typeof(data.package) !== "undefined" ? data.package.includedRoamingGb + data.subscription.includedRoamingGb : data.subscription.includedRoamingGb} displayType={'text'} decimalScale={2} thousandSeparator={'.'} decimalSeparator={','}/> {locale.text.dataEnding}</div>
                        <div className="statusgbright"></div>
                    </div>
                    {/*ef data.dataRemainingGb er ekki skilað þá endalaust  {typeof(data.dataRemainingGb) !== "undefined" ? */}
                    {typeof(data.dataRemainingGb) !== "undefined" ?
                        <div className="textiCurrentData">
                            Þú átt eftir <strong><NumberFormat value={data.dataRemainingGb} displayType={'text'} decimalScale={2} thousandSeparator={'.'} decimalSeparator={','}/> {locale.text.dataEnding} </strong> af {data.has10x ?
                            <strong><NumberFormat value={typeof(data.package) !== "undefined" ? (data.package.includedDataGb + data.subscription.includedDataGb) * 10 : data.subscription.includedDataGb * 10} displayType={'text'} decimalScale={2} thousandSeparator={'.'} decimalSeparator={','}/> {locale.text.dataEnding}</strong>
                            :
                            <strong><NumberFormat value={typeof(data.package) !== "undefined" ? data.package.includedDataGb + data.subscription.includedDataGb : data.subscription.includedDataGb} displayType={'text'} decimalScale={2} thousandSeparator={'.'} decimalSeparator={','}/> {locale.text.dataEnding}</strong>
                        }
                        </div>
                        :
                        <div className="textiCurrentData"></div>
                    }
                </div>
            </div>
        )
    }
}

export default (DataLayout);