import React, { Component }  from 'react';
import Header from "../components/Header";
import Layout from '../components/utgardur/Layout';
import DataLayout from '../components/utgardur/DataLayout';
import Error from '../components/utgardur/Error';
import locale from '../lang/utgardur-locale.json';
import ReactGA from 'react-ga';
import './Utgardur.css';

class Utgardur extends Component {
    constructor(props) {
        super(props);
        if(props.state!=null){
            this.state = props.state;
        }else{
            this.state = {
                testString: "",
                data: [],
                validToken: true,
                id: window.location.search,
                token: new URLSearchParams(window.location.search).get('id'),
            };
        }
    }

    componentDidMount() {
        document.title = "Útgarður";
        this.initializeReactGA();
        if(this.state.data.length === 0){
        fetch(process.env.REACT_APP_BACKEND_ROUTE.concat("utgardur/getSubscriptionByToken/") + this.state.token)
            .then(response => {
                return response.json();
            })
            .then((data) => {
                this.setState({
                    data: data,
                    validToken: data.validToken,
                });
            }).catch((error) => {
            this.setState({data: {message: locale.errormessage.headingError}, validToken: false});
        });
        }
    }

    initializeReactGA = () => {
        try{
            ReactGA.initialize('UA-20106629-1');
        }
        catch(error){
            ReactGA.initialize('UA-20106629-1' , { testMode:true });
        }

        ReactGA.pageview('/breyta');
    };

    render() {
        const { data, validToken, testString } = this.state;
        return (
            <div>
                <Header/>
                <h1 style={{color: "red"}}>{testString}</h1>
                {validToken ?
                    <div className="contentwrapper">
                        {data.length === 0 ?
                            ''
                            :
                            <div className="layoutwrapper">
                                <DataLayout data={data} locale={locale} />
                                <Layout data={data} token={this.state.token} locale={locale}/>
                            </div>
                        }
                    </div>
                    :
                    <div className="contentwrapper">
                        <div className="layoutwrapper">
                            <Error data={data} locale={locale}/>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default Utgardur;