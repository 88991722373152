// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --blue-dark: #009fdb;
    --blue-darker: #03aef3;
    --blue: #00aef3;
    --blue-light: #00b7ff;
    --blue-lighter: #15bdff;
    --blue-lightest: #66c5e9;
    --blue-most-lightest: #61dafb;
    --green-dark: #72bf44;
    --green: #1ed760;
    --green-light: #20e364;
    --green-lighter: #7ee362;
    --gray-darkest: #555f64;
    --gray-darker: #808080;
    --gray-dark: #707070;
    --gray-medium: #ababab;
    --gray: #dcdcdc;
    --gray-light: #ebebeb;
    --gray-lighter: #f7f7f7;
    --gray-more-lighter: #f8f8f8;
    --gray-lightest: #e6e8f0;
    --magenta: #ff3968;
    --magenta-light: #ff5382;
    --red: #d0021b;
    --purple: #6f3596;
    --purple-lighter: #894fb0;
    --orange: #f47920;
    --orange-light: #f5802b;
    --off-white: #e6ebf0;
    --white: #fff;
    --black-light: #282c34;
}`, "",{"version":3,"sources":["webpack://./src/components/css/colors.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,sBAAsB;IACtB,eAAe;IACf,qBAAqB;IACrB,uBAAuB;IACvB,wBAAwB;IACxB,6BAA6B;IAC7B,qBAAqB;IACrB,gBAAgB;IAChB,sBAAsB;IACtB,wBAAwB;IACxB,uBAAuB;IACvB,sBAAsB;IACtB,oBAAoB;IACpB,sBAAsB;IACtB,eAAe;IACf,qBAAqB;IACrB,uBAAuB;IACvB,4BAA4B;IAC5B,wBAAwB;IACxB,kBAAkB;IAClB,wBAAwB;IACxB,cAAc;IACd,iBAAiB;IACjB,yBAAyB;IACzB,iBAAiB;IACjB,uBAAuB;IACvB,oBAAoB;IACpB,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":[":root {\n    --blue-dark: #009fdb;\n    --blue-darker: #03aef3;\n    --blue: #00aef3;\n    --blue-light: #00b7ff;\n    --blue-lighter: #15bdff;\n    --blue-lightest: #66c5e9;\n    --blue-most-lightest: #61dafb;\n    --green-dark: #72bf44;\n    --green: #1ed760;\n    --green-light: #20e364;\n    --green-lighter: #7ee362;\n    --gray-darkest: #555f64;\n    --gray-darker: #808080;\n    --gray-dark: #707070;\n    --gray-medium: #ababab;\n    --gray: #dcdcdc;\n    --gray-light: #ebebeb;\n    --gray-lighter: #f7f7f7;\n    --gray-more-lighter: #f8f8f8;\n    --gray-lightest: #e6e8f0;\n    --magenta: #ff3968;\n    --magenta-light: #ff5382;\n    --red: #d0021b;\n    --purple: #6f3596;\n    --purple-lighter: #894fb0;\n    --orange: #f47920;\n    --orange-light: #f5802b;\n    --off-white: #e6ebf0;\n    --white: #fff;\n    --black-light: #282c34;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
