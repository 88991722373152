import React, { Component } from 'react';
import MessageHandler from './components/tilbod/Message/MessageHandler';
import locale from './lang/tilbod-locale.json';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        // You can also log the error to an error reporting service
        console.error('Error caught by error boundary:', error, info);
    }

    render() {
        if (this.state.hasError) {
            // You can render a fallback UI here
            return <MessageHandler title={locale.errormessage.generic} description1={locale.errormessage.heading} description2={locale.errormessage.message} highlightedWords={['550 6000', 'siminn@siminn.is']} />;
        }
        return this.props.children;
    }
}

export default ErrorBoundary;