import React, {Component} from 'react';

import Message from "../components/tilbod/Message/Message";
import QuoteLayout from "../components/tilbod/QuoteLayout"
import locale from '../lang/tilbod-locale.json';
import 'url-search-params-polyfill';
import MessageHandler from '../components/tilbod/Message/MessageHandler';


require('es6-promise').polyfill();
require('isomorphic-fetch');

class App extends Component {

    constructor(props) {
        super(props);
        
        if(props.state!=null){
            this.state = props.state;
        }else{
            this.state = {
                data: undefined,
                validToken: true,
                id: window.location.search,
                token: new URLSearchParams(window.location.search).get('id'),
                isPayer: new URLSearchParams(window.location.search).get('cs'),
            };
        } 
    }

    componentDidMount() {
        document.title = "Tilbod";
        let self = this;
        if(this.state.data === undefined){
            fetch(process.env.REACT_APP_BACKEND_ROUTE.concat("tilbod/getOffer/") + this.state.token)
            .then(function (response) {
                if (!response.ok) {
                    self.setState({validToken: false});
                    return response.status === 404 ? {message: locale.errormessage.quoteNotFound} : {message: locale.errormessage.generic};
                }
                return response.json();
            })
            .then(function (json) {
                self.setState({data: json});
            }).catch(function (error) {
            self.setState({data: {message: locale.errormessage.generic}, validToken: false});
        });
        }
    }

    render() {
        const {data, validToken, url, isPayer} = this.state;
        if(data === undefined){
            return (
                <body className="body"/>
            )
        }
        else if(data.status && data.status !== "Presented"){
            return (
                <MessageHandler title={locale.message[data.status].heading} description1={locale.message[data.status].body} />
                );
        }
        else if (validToken) {
            if ((isPayer !== "true" && data.hasOwnerQuoteBeenAccepted === "true") || (isPayer === "true" && data.hasPayerQuoteBeenAccepted === "true")) {
                return (
                    <MessageHandler description1={locale.message.Accepted.heading} description2={locale.message.Accepted.body} />
                    );
            }
            return (<QuoteLayout data={data} cs={isPayer=== "true"} locale={locale.quoteLayout} url={url}/>)
        } else {
            return (
                <MessageHandler title={locale.errormessage.generic} description1={locale.errormessage.heading} description2={locale.errormessage.message} highlightedWords={['550 6000', 'siminn@siminn.is']}/>
                );
        }
    }
}

export default App;
