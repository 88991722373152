import React from 'react';
import './Message.css';
import PropTypes from "prop-types";

class Message extends React.Component {
    static propTypes = {
        header: PropTypes.string,
        text: PropTypes.string,
        message: PropTypes.string,
        text2: PropTypes.string
    };

    render() {
        const {header, text, message, text2} = this.props;
        return (
            <body className="body">
            <div className="tilbodsgerdcontentwrapper" style={{height: "100vh"}}>
                <div className="tilbodsheaderwrap">
                    <h2 className="heading-39" style={{textAlign: "center"}}>
                        {header}
                    </h2>
                    <div className="textiError">
                        {text}
                    </div>
                    <div className="textiError2">
                        {text2}
                    </div>
                </div>
            </div>
            </body>
        )
    }
}

export default (Message);