// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
}

.App-header {
    background-color: var(--black-light);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: var(--white);
}

.App-link {
    color: var(--blue-most-lightest);
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.layoutwrapper {
    width: 60%;
    height: auto;
    padding: 0px;
    background-color: var(--white);
    margin: auto;
}

.contentwrapper {
    padding: 0rem 9rem 5rem 5rem;
}

@media (max-width: 991px) {
    .layoutwrapper {
        width: 100%;
    }

    .contentwrapper {
        margin-bottom: 34rem;
        padding-right: 2rem;
        padding-left: 2rem;
        background-position: 100% 50%;
        background-size: cover;
    }
}

@media (max-width: 479px) {
    /*.layoutwrapper {
      padding-right: 10px;
      padding-left: 10px;
    }*/

    .contentwrapper {
        padding-right: 0rem;
        padding-left: 0rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Utgardur.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,4CAA4C;IAC5C,cAAc;AAClB;;AAEA;IACI,oCAAoC;IACpC,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,6BAA6B;IAC7B,mBAAmB;AACvB;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,YAAY;IACZ,8BAA8B;IAC9B,YAAY;AAChB;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI;QACI,WAAW;IACf;;IAEA;QACI,oBAAoB;QACpB,mBAAmB;QACnB,kBAAkB;QAClB,6BAA6B;QAC7B,sBAAsB;IAC1B;AACJ;;AAEA;IACI;;;MAGE;;IAEF;QACI,mBAAmB;QACnB,kBAAkB;IACtB;AACJ","sourcesContent":[".App {\n    text-align: center;\n}\n\n.App-logo {\n    animation: App-logo-spin infinite 20s linear;\n    height: 40vmin;\n}\n\n.App-header {\n    background-color: var(--black-light);\n    min-height: 100vh;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    font-size: calc(10px + 2vmin);\n    color: var(--white);\n}\n\n.App-link {\n    color: var(--blue-most-lightest);\n}\n\n@keyframes App-logo-spin {\n    from {\n        transform: rotate(0deg);\n    }\n    to {\n        transform: rotate(360deg);\n    }\n}\n\n.layoutwrapper {\n    width: 60%;\n    height: auto;\n    padding: 0px;\n    background-color: var(--white);\n    margin: auto;\n}\n\n.contentwrapper {\n    padding: 0rem 9rem 5rem 5rem;\n}\n\n@media (max-width: 991px) {\n    .layoutwrapper {\n        width: 100%;\n    }\n\n    .contentwrapper {\n        margin-bottom: 34rem;\n        padding-right: 2rem;\n        padding-left: 2rem;\n        background-position: 100% 50%;\n        background-size: cover;\n    }\n}\n\n@media (max-width: 479px) {\n    /*.layoutwrapper {\n      padding-right: 10px;\n      padding-left: 10px;\n    }*/\n\n    .contentwrapper {\n        padding-right: 0rem;\n        padding-left: 0rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
