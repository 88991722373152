import { extendTheme } from '@chakra-ui/react';

const customTheme = extendTheme({
  styles: {
    global: {
      body: {
        bg: 'white', // Set the background color to white
        fontFamily: 'Hvd fonts, sans-serif'
      },
    },
  },
  fonts: {
    body: 'Hvd fonts, sans-serif',
    heading: 'Hvd fonts, sans-serif',
  },
  components: {
    Text: {
      baseStyle: {
        // Default styles for the Text component
        fontFamily: 'Hvd fonts, sans-serif',
        color: '#12232C',
      },
      variants: {
        introHeader: {
            fontWeight: 700,
            fontSize: '55px',
            lineHeight: '56px'
          },
          introHeaderMobile: {
            fontWeight: 700,
            fontSize: '35px',
            lineHeight: '42px'
          },
        introDesc: {
          fontWeight: 400,
          fontSize: '22px',
          lineHeight: '28px'
        },
        offerPricesHeaders: {
          fontSize: '16px',
          fontWeight: 700,
          lineHeight: '20px',
          mb: 0
        },
        offerPricesHeadersMobile: {
          fontSize: '14px',
          fontWeight: 700,
          lineHeight: '21px',
          mb: 0
        },
        offerPricesHeadersForAllPrices: {
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '20px',
        },
        offerPrices: {
            fontSize: '35px',
            fontWeight: 700,
            lineHeight: '42px',
            mb: 0
        },
        sumUpPrices: {
          fontSize: '18px',
          fontWeight: 700,
          lineHeight: '27px',
          mb: 0
        },
        sumUpPricesAfterFather: {
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '27px',
          mb: 0
        },
        pricesMobileChild: {
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '20px',
          mb: 0
        },
        pricesChild: {
          fontSize: '18px',
          fontWeight: 400,
          lineHeight: '27px',
          mb: 0
        },
        pricesMobileChildMainProduct: {
          fontSize: '16px',
          fontWeight: 700,
          lineHeight: '20px',
          mb: 0
        },
        pricesChildMainProduct: {
          fontSize: '18px',
          fontWeight: 700,
          lineHeight: '27px',
          mb: 0
        },
        accordionTitle: {
          fontSize: '22px',
          fontWeight: 700,
          lineHeight: '33px',
        },
        terms: {
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '22px'
        },
        termsCheckbox: {
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '20px'
        },
        termsLinks: {
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '22px'
        },
        discountMessages: {
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '20px',
          fontStyle: 'italic'
        },
        installationCost: {
          fontWeight: 700,
          fontSize: '30px',
          lineHeight: '40px'
        },
        installationCostInfo: {
          fontSize: '18px',
          fontWeight: 400,
          lineHeight: '24px'
        },
        descriptionTitle: {
          fontWeight: 500,
          fontSize: '18px',
          lineHeight: '24px'
        },
        descriptionText: {
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '20px'
        },
        expireDateText: {
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '20px',
          color: '#000000'
        },
        expireDate: {
          fontSize: '16px',
          fontWeight: 700,
          lineHeight: '20px',
          color: '#12232C'
        }
      },
    },
  },
});

export default customTheme;