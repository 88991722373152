import React from 'react';

class Redirect extends React.Component {
    componentDidMount(){
        window.location.href = "https://www.siminn.is/404";
    }

    render() {
        return (
            <div>
            </div>
        )
    }
}

export default Redirect;