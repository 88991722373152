import React from 'react';
import PropTypes from "prop-types";
import './Error.css';

class Error extends React.Component {
    static propTypes = {
        locale: PropTypes.objectOf(PropTypes.object).isRequired,
        data: PropTypes.objectOf(PropTypes.string).isRequired,
    };

    render() {
        const { locale, data } = this.props;
        return (
            <div className="nuverandaskriftwrap">
                {data.message === "TOKEN_NOT_FOUND" || data.message === "TOKEN_OUTDATED" ?
                    <div>
                        <h1 className="heading">{locale.errormessage.headingNotFoundOrOutdated}</h1>
                        <div className="textiError">
                            {locale.errormessage.messageNotFoundOrOutdated}
                        </div>
                        <div className="textiError2">
                            Hafðu samband í Netspjalli á <a href="https://www.siminn.is/" target="_blank" className="insertlink" rel="noopener noreferrer">siminn.is</a> eða í síma 550 6000 til að gera breytingar á áskriftum og fá frekari upplýsingar.
                        </div>
                        <div className="textiError2">
                            {locale.errormessage.messageHelp} <a href="https://thjonustuvefur-m.siminn.is/sso/innskraning" target="_blank" className="insertlink" rel="noopener noreferrer">Þjónustuvef Símans</a>
                        </div>
                        <div className="textiError">
                            {locale.errormessage.messageBye}
                        </div>
                    </div>
                    :
                    <div>
                        <h1 className="heading">{data.message}</h1>
                        <div className="textiError">
                            {locale.errormessage.messageError}
                        </div>
                        <div className="textiError2">
                            Þú getur alltaf breytt áskriftinni þinni í símaappinu ef þú skráir þig þar inn með rafrænum skilríkjum.
                            Einnig geturðu heyrt í okkur í Netspjalli á <a href="https://www.siminn.is/" target="_blank" className="insertlink" rel="noopener noreferrer">siminn.is</a> eða í síma 550 6000.
                        </div>
                        <div className="textiError">
                            {locale.errormessage.messageBye}
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default (Error);