import React from 'react';

const SiminnLogo = () => {
    return (
        <svg width="139" height="60" viewBox="0 0 139 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28.2369 7.08476C31.1362 7.00023 33.464 6.15766 33.4361 5.20282C33.4083 4.24798 31.0354 3.54246 28.1361 3.62699C25.2368 3.71152 22.9091 4.55409 22.9369 5.50893C22.9647 6.46376 25.3376 7.16929 28.2369 7.08476Z" fill="#00BDFF" />
            <path d="M16.054 16.5677C20.1373 12.8469 22.0317 8.2768 20.2851 6.36003C18.5385 4.44325 13.8124 5.9057 9.72905 9.62648C5.6457 13.3473 3.75136 17.9174 5.49794 19.8342C7.24451 21.751 11.9706 20.2885 16.054 16.5677Z" fill="#00BDFF" />
            <path d="M16.8043 41.3078C19.9339 39.2159 19.9853 33.8014 16.9191 29.2143C13.8529 24.6271 8.83026 22.6043 5.70066 24.6962C2.57105 26.7882 2.51965 32.2026 5.58584 36.7898C8.65204 41.3769 13.6747 43.3997 16.8043 41.3078Z" fill="#00BDFF" />
            <path d="M28.0288 46.0909C32.0684 46.0909 35.343 44.0805 35.343 41.6005C35.343 39.1205 32.0684 37.1101 28.0288 37.1101C23.9893 37.1101 20.7146 39.1205 20.7146 41.6005C20.7146 44.0805 23.9893 46.0909 28.0288 46.0909Z" fill="#00BDFF" />
            <path d="M29.3986 57.0928C32.2979 57.0083 34.6239 56.1047 34.5939 55.0747C34.5639 54.0446 32.1892 53.2781 29.2899 53.3626C26.3906 53.4471 24.0646 54.3507 24.0947 55.3808C24.1247 56.4109 26.4994 57.1774 29.3986 57.0928Z" fill="#00BDFF" />
            <path d="M44.9888 52.316C48.2119 49.6192 49.7577 46.1577 48.4414 44.5845C47.1251 43.0114 43.4452 43.9224 40.2221 46.6192C36.999 49.3161 35.4532 52.7776 36.7695 54.3507C38.0858 55.9238 41.7657 55.0129 44.9888 52.316Z" fill="#00BDFF" />
            <path d="M48.8078 42.1042C52.1615 41.2761 53.882 36.5622 52.6507 31.5755C51.4194 26.5888 47.7025 23.2175 44.3488 24.0456C40.995 24.8737 39.2745 29.5876 40.5058 34.5743C41.7371 39.5611 45.454 42.9323 48.8078 42.1042Z" fill="#00BDFF" />
            <path d="M29.9615 29.3926C35.2922 29.3926 39.6135 26.2471 39.6135 22.3669C39.6135 18.4868 35.2922 15.3413 29.9615 15.3413C24.6308 15.3413 20.3095 18.4868 20.3095 22.3669C20.3095 26.2471 24.6308 29.3926 29.9615 29.3926Z" fill="#00BDFF" />
            <path d="M123.739 29.7833V42.0344C123.739 42.1435 123.827 42.2318 123.936 42.2318H126.745C126.854 42.2318 126.942 42.1435 126.942 42.0344V31.6689C126.942 31.6492 126.956 31.6313 126.975 31.6266C127.495 31.5101 128.293 31.4029 129.066 31.4029C130.957 31.4029 131.729 32.217 131.729 34.2126V42.0335C131.729 42.1425 131.817 42.2309 131.926 42.2309H134.735C134.844 42.2309 134.933 42.1425 134.933 42.0335V34.1628C134.933 30.4535 132.904 28.4926 129.066 28.4926C125.915 28.4926 123.914 29.5718 123.914 29.5718L123.844 29.6084C123.779 29.6423 123.739 29.709 123.739 29.7823V29.7833Z" fill="#00BDFF" />
            <path d="M109.398 29.7833V42.0335C109.398 42.1425 109.486 42.2309 109.595 42.2309H112.404C112.513 42.2309 112.602 42.1425 112.602 42.0335V31.6689C112.602 31.6492 112.615 31.6313 112.635 31.6266C113.154 31.5101 113.952 31.4029 114.725 31.4029C116.616 31.4029 117.388 32.217 117.388 34.2126V42.0344C117.388 42.1435 117.477 42.2318 117.586 42.2318H120.394C120.503 42.2318 120.592 42.1435 120.592 42.0344V34.1637C120.592 30.4544 118.563 28.4936 114.725 28.4936C111.574 28.4936 109.573 29.5727 109.573 29.5727L109.503 29.6094C109.438 29.6432 109.398 29.71 109.398 29.7833Z" fill="#00BDFF" />
            <path d="M106.039 28.9166H103.233C103.123 28.9166 103.034 29.0054 103.034 29.1149V42.0344C103.034 42.144 103.123 42.2328 103.233 42.2328H106.039C106.149 42.2328 106.238 42.144 106.238 42.0344V29.1149C106.238 29.0054 106.149 28.9166 106.039 28.9166Z" fill="#00BDFF" />
            <path d="M104.66 24.0549C103.636 24.0549 102.862 24.8172 102.862 25.8287C102.862 26.8401 103.652 27.6269 104.66 27.6269C105.669 27.6269 106.434 26.8542 106.434 25.8287C106.434 24.8031 105.689 24.0549 104.66 24.0549Z" fill="#00BDFF" />
            <path d="M94.6615 28.503C92.6847 28.503 91.4561 29.0388 90.815 29.4383L90.5997 29.568L90.4146 29.4383C89.4708 28.8198 88.2225 28.503 86.6818 28.503C83.5309 28.503 81.5296 29.5821 81.5296 29.5821L81.46 29.6188C81.3952 29.6526 81.3547 29.7193 81.3547 29.7927V42.0429C81.3547 42.1519 81.4431 42.2403 81.5521 42.2403H84.3609C84.4699 42.2403 84.5583 42.1519 84.5583 42.0429V31.6783C84.5583 31.6586 84.5715 31.6407 84.5912 31.636C85.111 31.5195 85.9091 31.4123 86.6818 31.4123C88.5731 31.4123 89.3448 32.2264 89.3448 34.222V42.0438C89.3448 42.1529 89.4332 42.2412 89.5422 42.2412H92.351C92.46 42.2412 92.5484 42.1529 92.5484 42.0438V34.1731C92.5484 33.3262 92.4412 32.5704 92.2325 31.9086L92.5747 31.7281C92.6104 31.7122 93.2985 31.4123 94.6625 31.4123C96.5538 31.4123 97.3255 32.2264 97.3255 34.222V42.0429C97.3255 42.1519 97.4139 42.2403 97.5229 42.2403H100.332C100.441 42.2403 100.529 42.1519 100.529 42.0429V34.1722C100.529 30.4629 98.5005 28.502 94.6625 28.502L94.6615 28.503Z" fill="#00BDFF" />
            <path d="M78.0412 28.9166H75.2362C75.1261 28.9166 75.0369 29.0058 75.0369 29.1159V42.0335C75.0369 42.1436 75.1261 42.2328 75.2362 42.2328H78.0412C78.1512 42.2328 78.2405 42.1436 78.2405 42.0335V29.1159C78.2405 29.0058 78.1512 28.9166 78.0412 28.9166Z" fill="#00BDFF" />
            <path d="M77.2995 27.358L79.7313 24.3218C79.8272 24.2024 79.7398 24.0248 79.5866 24.0285H77.2243C77.0194 24.0332 76.8342 24.1507 76.7411 24.3331L75.1723 27.4276C75.1403 27.4906 75.1864 27.5658 75.2578 27.5658H76.8652C77.0344 27.5658 77.1933 27.4887 77.2995 27.3571V27.358Z" fill="#00BDFF" />
            <path d="M61.7273 36.2882C61.8307 36.2882 61.9181 36.3634 61.9331 36.4649C62.0441 37.2094 62.5037 39.2548 65.5588 39.2548C67.5836 39.2548 68.6758 38.4972 68.6721 37.2724C68.6721 33.4587 58.7493 34.818 58.7493 28.7972C58.7399 25.8099 61.1915 23.6131 64.8951 23.6131C70.3218 23.6131 71.5711 27.625 71.7722 28.6675C71.7967 28.7953 71.6999 28.9138 71.5701 28.9147L68.1466 28.9166C68.0554 28.9166 67.9746 28.8602 67.9464 28.7737C67.7932 28.3 67.137 26.8599 64.9026 26.8599C62.3496 26.8599 62.3881 28.7023 62.3881 28.7023C62.3881 31.7498 72.3381 30.6989 72.3381 37.2028C72.3485 40.3001 69.6685 42.5035 65.4394 42.5035C61.2103 42.5035 58.6027 40.3443 58.0772 36.5269C58.0603 36.401 58.1562 36.2882 58.284 36.2882H61.7292H61.7273Z" fill="#00BDFF" />
        </svg>
    );
}

export default SiminnLogo;