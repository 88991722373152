import React from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'perfect-scrollbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft';
import NumberFormat from 'react-number-format';
import { Modal } from 'react-responsive-modal';
import ReactLoading from 'react-loading';
import 'react-responsive-modal/styles.css';
import './Layout.css';

class Layout extends React.Component {
    static propTypes = {
        locale: PropTypes.objectOf(PropTypes.object).isRequired,
        data: PropTypes.objectOf(PropTypes.any).isRequired,
        token: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            newIncludedDataGb: typeof(this.props.data.expansionsAvailable) === 'undefined' ? null : this.props.data.expansionsAvailable[0].includedDataGb + (this.props.data.expansionType === 'package' ? this.props.data.subscription.includedDataGb : 0),
            newRoamningGb: typeof(this.props.data.expansionsAvailable) === 'undefined' ? null : this.props.data.expansionsAvailable[0].includedRoamingGb + (this.props.data.expansionType === 'package' ? this.props.data.subscription.includedRoamingGb : 0),
            newMonthlyCharge: typeof(this.props.data.expansionsAvailable) === 'undefined' ? null : this.props.data.expansionsAvailable[0].monthlyCharge + (this.props.data.expansionType === 'package' ? this.props.data.subscription.monthlyCharge : 0),
            ratePlan: typeof(this.props.data.expansionsAvailable) === 'undefined' ? null : this.props.data.expansionsAvailable[0].ratePlan,
            active: 0,
            psContainer: null,
            intervalHandler: null,
            open: false,
            openError: false,
            openAccept: false,
            showButton: true,
        };
        this.goLeft = this.goLeft.bind(this);
        this.goRight = this.goRight.bind(this);
        this.stopScrolling = this.stopScrolling.bind(this);
        this.confirmTerms = this.confirmTerms.bind(this);
    }

    componentDidMount() {
        const container = document.querySelector('.subscriptionwrap');
        if(container != null) {
            const ps = new PerfectScrollbar(container);
        }
        this.setState({
            psContainer: container
        });
    }

    goLeft() {
        let psContainer = this.state.psContainer;
        this.setState({intervalHandler: setInterval(function() { psContainer.scrollLeft -= 1; }, 20)});
        return false;
    }

    goRight() {
        let psContainer = this.state.psContainer;
        this.setState({intervalHandler: setInterval(function() { psContainer.scrollLeft += 1; }, 20)});
        return false;
    }

    stopScrolling() {
        clearInterval(this.state.intervalHandler);
    }

    createOrder(token) {
        this.onCloseAcceptModal();
        this.setState({
            showButton: false
        });

        fetch(process.env.REACT_APP_BACKEND_ROUTE.concat("utgardur/createOrder"), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({token: token, requestedRatePlan: this.state.ratePlan})
        }).then((res) => {
            if(res.ok) {
                return res.json();
            } else {
                throw new Error(res.status);
            }
        }).then((data) => {
            this.setState({
                showButton: true
            });

            this.onOpenModal();
        }).catch((error) => {
            console.error(error);
            this.setState({
                showButton: true
            });
            this.onOpenErrorModal();
        });
    }

    handleClick(value, i) {
        this.setState({
            newIncludedDataGb: value.includedDataGb + (this.props.data.expansionType === 'package' ? this.props.data.subscription.includedDataGb : 0),
            newRoamningGb: value.includedRoamingGb + (this.props.data.expansionType === 'package' ? this.props.data.subscription.includedRoamingGb : 0),
            newMonthlyCharge: value.monthlyCharge + (this.props.data.expansionType === 'package' ? this.props.data.subscription.monthlyCharge : 0),
            isEndalaus: value.includedDataGb === -1,
            ratePlan: value.ratePlan,
            active: i,
        });
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onOpenErrorModal = () => {
        this.setState({ openError: true });
    };

    onOpenAcceptModal = () => {
        this.setState({ openAccept: true });
    };

    confirmTerms= () => {
        this.setState({disableBuyButton: !this.state.disableBuyButton})
    };

    onCloseModal = () => {
        window.location = "https://www.siminn.is/";
    };

    onCloseErrorModal = () => {
        this.setState({ openError: false });
    };

    onCloseAcceptModal = () => {
        this.setState({ openAccept: false });
    };

    render() {
        const { newIncludedDataGb, newRoamningGb, newMonthlyCharge, isEndalaus, active, disableBuyButton, open, openError, openAccept } = this.state;
        const { locale, data, token } = this.props;
        return (
            <div>
                <div className="_2cols">
                    {typeof (data.expansionsAvailable) === 'undefined' ?
                        <div className="col">
                            {data.expansionType === 'notAvailable' ?
                                <div className="texti">
                                    {locale.layout.messageNoChange} <a href="https://www.siminn.is/" target="_blank" className="insertlink" rel="noopener noreferrer">siminn.is</a>
                                </div>
                                :
                                <div className="texti">
                                    {locale.layout.messageNoExpansion}
                                </div>
                            }
                        </div>
                        :
                        <div className="col">
                            <h1 className="heading-3">{data.expansionType === "package" ? locale.layout.titlePackage : locale.layout.titleSubscription}</h1>
                            <div className="hr"></div>
                            <div className="scrollwrap">
                                <div className="arrowleft" onMouseUp={this.stopScrolling} onMouseDown={this.goLeft}>
                                    <FontAwesomeIcon icon={faAngleLeft}/>
                                </div>
                                <div className="subscriptionwrap">
                                    <div className="horscrollbtnwrap">
                                        {data.expansionsAvailable.map((item, index) =>
                                            <div className={active === index ? "gb-buttton active" : "gb-buttton overlay"} key={item.ratePlan} onClick={() => this.handleClick(item, index)}>
                                                <div className="div-block-50">
                                                    <div className="text-block-33">
                                                        {typeof(data.package) !== "undefined" ?
                                                            item.includedDataGb === -1 ?
                                                                <span className="text-block-37">{item.ratePlan === 'GNU12' ? 'Endalaus með Netinu' : 'Endalaus \xa0' }</span>
                                                                :
                                                                <NumberFormat value={item.includedDataGb - (data.expansionType === "package" ? data.package.includedDataGb : data.subscription.includedDataGb)} displayType={'text'} decimalScale={2} thousandSeparator={'.'} decimalSeparator={','}/>
                                                            :
                                                            item.includedDataGb === -1 ?
                                                                <span className="text-block-37">{item.ratePlan === 'GNU12' ? 'Endalaus með Netinu' : 'Endalaus \xa0' }</span>
                                                                :
                                                                data.expansionType === "package" ?
                                                                    <NumberFormat value={item.includedDataGb} displayType={'text'} decimalScale={2} thousandSeparator={'.'} decimalSeparator={','}/>
                                                                    :
                                                                    <NumberFormat value={item.includedDataGb - data.subscription.includedDataGb} displayType={'text'} decimalScale={2} thousandSeparator={'.'} decimalSeparator={','}/>
                                                        }
                                                    </div>
                                                    <div className="text-block-34">{locale.text.dataEnding}</div>
                                                </div>
                                                {typeof(data.package) !== "undefined" ?
                                                    <div className="text-block-35">
                                                        fyrir <NumberFormat value={item.monthlyCharge - (data.expansionType === "package" ? data.package.monthlyCharge : data.subscription.monthlyCharge)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','}/> kr. aukalega á mánuði
                                                    </div>
                                                    :
                                                    data.expansionType === "package" ?
                                                        <div className="text-block-35">
                                                            fyrir <NumberFormat value={item.monthlyCharge} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','}/> kr. aukalega á mánuði
                                                        </div>
                                                        :
                                                        <div className="text-block-35">
                                                            fyrir <NumberFormat value={item.monthlyCharge - data.subscription.monthlyCharge} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','}/> kr. aukalega á mánuði
                                                        </div>
                                                }
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="arrowright" onMouseUp={this.stopScrolling} onMouseDown={this.goRight}>
                                    <FontAwesomeIcon icon={faAngleRight}/>
                                </div>
                            </div>
                            {(isEndalaus || newIncludedDataGb === "-1") ?
                                data.expansionType === "package" ?
                                    <div className="texti">
                                        Eftir stækkun mun gagnamagnið verða <strong>endalaus GB</strong>, þar
                                        af <NumberFormat value={newRoamningGb} displayType={'text'} decimalScale={2} thousandSeparator={'.'} decimalSeparator={','}/> GB innifalin
                                        í <a href="https://www.siminn.is/adstod/farsimi#utlond-ees" target="_blank" className="insertlink" rel="noopener noreferrer">EES</a>. Mánaðargjald
                                        verður <NumberFormat value={newMonthlyCharge} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','}/> kr.
                                    </div>
                                    :
                                    <div className="texti">
                                        Eftir stækkun verður áskriftin <strong>endalaus GB</strong>, þar
                                        af <NumberFormat value={newRoamningGb} displayType={'text'} decimalScale={2} thousandSeparator={'.'} decimalSeparator={','}/> GB innifalin
                                        í <a href="https://www.siminn.is/adstod/farsimi#utlond-ees" target="_blank" className="insertlink" rel="noopener noreferrer">EES</a>. Mánaðargjald
                                        verður <NumberFormat value={newMonthlyCharge} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','}/> kr.
                                    </div>
                                :
                                data.has10x ?
                                    data.expansionType === "package" ?
                                        <div className="texti">
                                            Eftir stækkun mun gagnamagnið verða <strong><NumberFormat value={newIncludedDataGb} displayType={'text'} decimalScale={2} thousandSeparator={'.'} decimalSeparator={','}/> GB</strong>, þar
                                            af <NumberFormat value={newRoamningGb} displayType={'text'} decimalScale={2} thousandSeparator={'.'} decimalSeparator={','}/> GB innifalin
                                            í <a href="https://www.siminn.is/adstod/farsimi#utlond-ees" target="_blank" className="insertlink" rel="noopener noreferrer">EES</a>. Þar sem þú ert með 10x verður
                                            innifalið gagnamagn <strong><NumberFormat value={newIncludedDataGb * 10} displayType={'text'} decimalScale={2} thousandSeparator={'.'} decimalSeparator={','}/> GB</strong>.
                                            Mánaðargjald verður <NumberFormat value={newMonthlyCharge} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','}/> kr.
                                        </div>
                                        :
                                        <div className="texti">
                                            Eftir stækkun verður áskriftin <strong><NumberFormat value={newIncludedDataGb} displayType={'text'} decimalScale={2} thousandSeparator={'.'} decimalSeparator={','}/> GB</strong>, þar
                                            af <NumberFormat value={newRoamningGb} displayType={'text'} decimalScale={2} thousandSeparator={'.'} decimalSeparator={','}/> GB innifalin
                                            í <a href="https://www.siminn.is/adstod/farsimi#utlond-ees" target="_blank" className="insertlink" rel="noopener noreferrer">EES</a>.
                                            Þar sem þú ert með 10x verður innifalið gagnamagn <strong><NumberFormat value={newIncludedDataGb * 10} displayType={'text'} decimalScale={2} thousandSeparator={'.'} decimalSeparator={','}/> GB</strong>.
                                            Mánaðargjald verður <NumberFormat value={newMonthlyCharge} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','}/> kr.
                                        </div>
                                    :
                                    data.expansionType === "package" ?
                                        <div className="texti">
                                            Eftir stækkun mun gagnamagnið verða <strong><NumberFormat value={newIncludedDataGb} decimalScale={2} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','}/> GB</strong>, þar
                                            af <NumberFormat value={newRoamningGb} displayType={'text'} decimalScale={2} thousandSeparator={'.'} decimalSeparator={','}/> GB innifalin
                                            í <a href="https://www.siminn.is/adstod/farsimi#utlond-ees" target="_blank" className="insertlink" rel="noopener noreferrer">EES</a>. Mánaðargjald
                                            verður <NumberFormat value={newMonthlyCharge} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','}/> kr.
                                        </div>
                                        :
                                        <div className="texti">
                                            Eftir stækkun verður áskriftin <strong><NumberFormat value={newIncludedDataGb} decimalScale={2} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','}/> GB</strong>, þar
                                            af <NumberFormat value={newRoamningGb} displayType={'text'} decimalScale={2} thousandSeparator={'.'} decimalSeparator={','}/> GB innifalin
                                            í <a href="https://www.siminn.is/adstod/farsimi#utlond-ees" target="_blank" className="insertlink" rel="noopener noreferrer">EES</a>. Mánaðargjald
                                            verður <NumberFormat value={newMonthlyCharge} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','}/> kr.
                                        </div>

                            }
                            {this.state.showButton ?
                                <div>
                                    <div className="skilmalar">
                                        <label className="skilmalartext">
                                            <input type="checkbox" onClick={this.confirmTerms}></input>
                                            Samþykkja <a href="https://www.siminn.is/skilmalar/simi" target="_blank" rel="noopener noreferrer" className="insertlink">skilmála</a>
                                        </label>
                                    </div>
                                    <button className="kaupabtn w-button" disabled={!this.state.disableBuyButton} onClick={this.onOpenAcceptModal}>{locale.layout.buyButton}</button>
                                </div>
                                :
                                <ReactLoading type={'bubbles'} color={'#03aef3'} height={'10%'} width={'10%'}/>
                            }
                            <Modal open={openAccept} onClose={this.onCloseAcceptModal} little>
                                <div className="logowrapper">
                                    <div className="stadfestingtopwrap">
                                        <img src="https://static.siminn.is/tilbod/logo.png" alt="" width="80" className="stadfestinglogo" rel="noopener noreferrer"/>
                                        <div className="stadfestingheader">{locale.modalText.titleAccept}</div>
                                    </div>
                                </div>
                                <div className="stadfestingbottomwrap">
                                    <div className="stadfestingtext">
                                        <strong>
                                            {locale.modalText.messageAccept}
                                        </strong>
                                    </div>
                                    <button className="btn btnNo w-button" onClick={this.onCloseAcceptModal}>{locale.modalText.buttonAcceptNo}</button>
                                    <button className="btn btnYes w-button" onClick={() => this.createOrder(token)}>{locale.modalText.buttonAccpetYes}</button>
                                </div>
                            </Modal>
                            <Modal open={open} onClose={this.onCloseModal} little>
                                <div className="logowrapper">
                                    <div className="stadfestingtopwrap">
                                        <img src="https://static.siminn.is/tilbod/logo.png" alt="" width="80" className="stadfestinglogo" rel="noopener noreferrer"/>
                                        <div className="stadfestingheader">{locale.modalText.title}</div>
                                    </div>
                                </div>
                                <div className="stadfestingbottomwrap">
                                    {data.has10x ?
                                        data.expansionType === "package" ?
                                            <div className="stadfestingtext">
                                                <strong>
                                                    Netpakkinn þinn hefur verið stækkaður í <NumberFormat value={newIncludedDataGb} displayType={'text'} decimalScale={2} thousandSeparator={'.'} decimalSeparator={','}/> GB.
                                                    (<NumberFormat value={newIncludedDataGb * 10} displayType={'text'} decimalScale={2} thousandSeparator={'.'} decimalSeparator={','}/> GB með 10x).
                                                    Mánaðargjald er framvegis <NumberFormat value={newMonthlyCharge} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','}/> kr.
                                                </strong>
                                                <br/>
                                                Fylgstu með notkuninni í <a href="https://vas.siminn.is/" target="_blank" className="insertlink" rel="noopener noreferrer">Símaappinu</a>
                                            </div>
                                            :
                                            <div className="stadfestingtext">
                                                <strong>
                                                    Áskriftin þín hefur verið stækkuð í <NumberFormat value={newIncludedDataGb} displayType={'text'} decimalScale={2} thousandSeparator={'.'} decimalSeparator={','}/> GB
                                                    (<NumberFormat value={newIncludedDataGb * 10} displayType={'text'} decimalScale={2} thousandSeparator={'.'} decimalSeparator={','}/> GB með 10x).
                                                    Mánaðargjald er framvegis <NumberFormat value={newMonthlyCharge} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','}/> kr.
                                                </strong>
                                                <br/>
                                                Fylgstu með notkuninni í <a href="https://vas.siminn.is/" target="_blank" className="insertlink" rel="noopener noreferrer">Símaappinu</a>
                                            </div>
                                        :
                                        data.expansionType === "package" ?
                                            <div className="stadfestingtext">
                                                <strong>
                                                    Netpakkinn þinn hefur verið stækkaður í <NumberFormat value={newIncludedDataGb} displayType={'text'} decimalScale={2} thousandSeparator={'.'} decimalSeparator={','}/> GB.
                                                    Mánaðargjald er framvegis <NumberFormat value={newMonthlyCharge} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','}/> kr.
                                                </strong>
                                                <br/>
                                                Fylgstu með notkuninni í <a href="https://vas.siminn.is/" target="_blank" className="insertlink" rel="noopener noreferrer">Símaappinu</a>
                                            </div>
                                            :
                                            <div className="stadfestingtext">
                                                <strong>
                                                    Áskriftin þín hefur verið stækkuð í <NumberFormat value={newIncludedDataGb} displayType={'text'} decimalScale={2} thousandSeparator={'.'} decimalSeparator={','}/> GB.
                                                    Mánaðargjald er framvegis <NumberFormat value={newMonthlyCharge} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','}/> kr.
                                                </strong>
                                                <br/>
                                                Fylgstu með notkuninni í <a href="https://vas.siminn.is/" target="_blank" className="insertlink" rel="noopener noreferrer">Símaappinu</a>
                                            </div>
                                    }
                                    <button className="innskrabtn w-button" onClick={this.onCloseModal}>{locale.modalText.button}</button>
                                </div>
                            </Modal>
                            <Modal open={openError} onClose={this.onCloseErrorModal} little>
                                <div className="logowrapper">
                                    <div className="stadfestingtopwrap">
                                        <img src="https://static.siminn.is/tilbod/logo.png" alt="" width="80" className="stadfestinglogo" rel="noopener noreferrer"/>
                                        <div className="stadfestingheader">{locale.modalText.titleError}</div>
                                    </div>
                                </div>
                                <div className="stadfestingbottomwrap">
                                    <div className="stadfestingtext">
                                        <strong>
                                            {locale.modalText.message}
                                        </strong>
                                        <br/>
                                        Hafðu samband í Netspjalli á <a href="https://www.siminn.is/" target="_blank" className="insertlink" rel="noopener noreferrer">siminn.is</a> eða í síma 550 6000 til að gera breytingar á áskriftum og fá frekari upplýsingar.
                                    </div>
                                    <button className="innskrabtn w-button" onClick={this.onCloseErrorModal}>{locale.modalText.button}</button>
                                </div>
                            </Modal>
                        </div>
                    }
                    {typeof (data.expansionsAvailable) === 'undefined' ?
                        //ef má ekki breyta
                        data.expansionType === "notAvailable"?
                            data.throttleable ?
                                <div className="col">
                                    <div className="hr"></div>
                                    <div className="texti">{locale.layout.throttleable}</div>
                                </div>
                                :
                                <div className="col">
                                    <div className="hr"></div>
                                    <div className="texti">
                                        Þegar innifalið gagnamagn klárast verður, verður <strong><NumberFormat
                                        value={data.defaultExpansion.dataGb} displayType={'text'} decimalScale={2}
                                        thousandSeparator={'.'} decimalSeparator={','}/> {locale.text.dataEnding} bætt við
                                        fyrir <NumberFormat value={data.defaultExpansion.price} displayType={'text'}
                                                            thousandSeparator={'.'}
                                                            decimalSeparator={','}/> kr.</strong> endurtekið þar til mánuði
                                        lýkur.
                                    </div>
                                    <div className = "note">
                                        Gildir ekki innan <a href="https://www.siminn.is/adstod/farsimi#utlond-ees" target="_blank" className="insertlink" rel="noopener noreferrer">EES</a> né <a href="https://www.siminn.is/adstodarflokkur/10x" target="_blank" className="insertlink" rel="noopener noreferrer">10x</a> þjónustunni.
                                    </div>
                                </div>
                            :
                            // í stærstu áskriftinni
                            data.expansionType === "noExpansion" ?
                                <div className="col">
                                    <div className="note"> {locale.layout.noteExpansion}</div>
                                </div>
                                :
                                <div className="col">
                                    <div className="note"> {locale.layout.noteExpansion}</div>
                                    <div className="texti">
                                        Þegar innifalið gagnamagn klárast verður <strong><NumberFormat value={data.defaultExpansion.dataGb} displayType={'text'} decimalScale={2} thousandSeparator={'.'} decimalSeparator={','}/> {locale.text.dataEnding} bætt við fyrir <NumberFormat value={data.defaultExpansion.price} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','}/> kr.</strong> endurtekið þar til mánuði lýkur.
                                    </div>
                                    <div className="note">
                                        Gildir ekki innan <a href="https://www.siminn.is/adstod/farsimi#utlond-ees" target="_blank" className="insertlink" rel="noopener noreferrer">EES</a> né <a href="https://www.siminn.is/adstodarflokkur/10x" target="_blank" className="insertlink" rel="noopener noreferrer">10x</a> þjónustunni.
                                    </div>
                                </div>
                        :
                        data.throttleable ?
                            <div className="col">
                                <div className="hr"></div>
                                <div className="texti">{locale.layout.throttleable}</div>
                            </div>
                            :
                            <div className="col">
                                <div className="hr"></div>
                                <div className="texti">
                                    Ef ekkert er valið og innifalið gagnamagn klárast, verður <strong><NumberFormat
                                    value={data.defaultExpansion.dataGb} displayType={'text'} decimalScale={2}
                                    thousandSeparator={'.'} decimalSeparator={','}/> {locale.text.dataEnding} bætt við
                                    fyrir <NumberFormat value={data.defaultExpansion.price} displayType={'text'}
                                                        thousandSeparator={'.'}
                                                        decimalSeparator={','}/> kr.</strong> endurtekið þar til mánuði
                                    lýkur.
                                </div>
                                <div className = "note">
                                    Gildir ekki innan <a href="https://www.siminn.is/adstod/farsimi#utlond-ees" target="_blank" className="insertlink" rel="noopener noreferrer">EES</a> né <a href="https://www.siminn.is/adstodarflokkur/10x" target="_blank" className="insertlink" rel="noopener noreferrer">10x</a> þjónustunni.
                                </div>
                            </div>
                    }
                </div>
            </div>
        );
    }
}

export default (Layout);
