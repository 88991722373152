import React from "react";
import { Box, Grid, Text, useBreakpointValue, GridItem, Stack } from "@chakra-ui/react";
import RecursiveAccordion from "./RecursiveAccordion";

function HeaderAccordion({ products, isOwner, CPQv2, headerMessage, actionIgnore }) {

    const isMobile = useBreakpointValue({ base: true, md: false });

    return (
        <React.Fragment>
            <Grid templateColumns={`repeat(${isMobile ? 4 : 12}, 1fr)`} gap={4} p={5} width="100%">
                <GridItem colStart={isMobile ? 1 : 2} colSpan={isMobile ? 4 : 10}>
                    <Box>
                        <Text variant="accordionTitle">
                            {headerMessage}
                        </Text>
                    </Box>
                    <Box bg="#F4F7FD" borderRadius="md" p={isMobile ? "16px" : 4} pl={isMobile ? "16px" : "40px"} pt={isMobile ? "16px" : "32px"}>
                        <Grid templateColumns="1fr 1fr 1fr 20px" p={0}>
                            <Box>
                                <Text variant={isMobile ? "offerPricesHeadersMobile" : "offerPricesHeaders"}>Þjónusta</Text>
                            </Box>
                            <Box textAlign="right">
                                <Text variant={isMobile ? "offerPricesHeadersMobile" : "offerPricesHeaders"}>Kynningarverð</Text>
                            </Box>
                            <Box textAlign="right" mr={isMobile ? "2%" : "20%"}>
                                <Text variant={isMobile ? "offerPricesHeadersMobile" : "offerPricesHeaders"}>Mánaðarverð</Text>
                            </Box>
                        </Grid>

                        <Stack mt={4} spacing={4}>
                            <RecursiveAccordion products={products} isOwner={isOwner} CPQv2={CPQv2} actionIgnore={actionIgnore} />
                        </Stack>


                    </Box>
                </GridItem>

            </Grid>
        </React.Fragment>
    );
}

export default HeaderAccordion;
