// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textiError {
    padding-top: 0px;
    padding-bottom: 0px;
    color: var(--gray-darkest);
    font-size: 22px;
    line-height: 28px;
    font-weight: 300;
    text-align: center;
}

.textiError2 {
    margin-top: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
    color: var(--gray-darkest);
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;
    text-align: center;
}

.heading {
    white-space: nowrap;
    margin-top: 30px;
    color: var(--gray-dark);
    font-size: 35px;
}

.nuverandaskriftwrap {
    width: 100%;
    padding-bottom: 30px;
    background-color: var(--gray-more-lighter);
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 479px) {


    .textiError {
        width: 90%;
        font-size: 18px;
    }

    .textiError2 {
        width: 90%;
        font-size: 15px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/tilbod/Message/Message.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,0BAA0B;IAC1B,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;IACnB,0BAA0B;IAC1B,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,oBAAoB;IACpB,0CAA0C;IAE1C,aAAa;IAEb,sBAAsB;IAEtB,mBAAmB;AACvB;;AAEA;;;IAGI;QACI,UAAU;QACV,eAAe;IACnB;;IAEA;QACI,UAAU;QACV,eAAe;IACnB;AACJ","sourcesContent":[".textiError {\n    padding-top: 0px;\n    padding-bottom: 0px;\n    color: var(--gray-darkest);\n    font-size: 22px;\n    line-height: 28px;\n    font-weight: 300;\n    text-align: center;\n}\n\n.textiError2 {\n    margin-top: 10px;\n    padding-top: 0px;\n    padding-bottom: 0px;\n    color: var(--gray-darkest);\n    font-size: 18px;\n    line-height: 28px;\n    font-weight: 300;\n    text-align: center;\n}\n\n.heading {\n    white-space: nowrap;\n    margin-top: 30px;\n    color: var(--gray-dark);\n    font-size: 35px;\n}\n\n.nuverandaskriftwrap {\n    width: 100%;\n    padding-bottom: 30px;\n    background-color: var(--gray-more-lighter);\n    display: -webkit-flex;\n    display: flex;\n    -webkit-flex-direction: column;\n    flex-direction: column;\n    -webkit-align-items: center;\n    align-items: center;\n}\n\n@media (max-width: 479px) {\n\n\n    .textiError {\n        width: 90%;\n        font-size: 18px;\n    }\n\n    .textiError2 {\n        width: 90%;\n        font-size: 15px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
