import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';

import './index.css';
import './components/css/core.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import customTheme from './themes/customTheme';


ReactDOM.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    document.getElementById('root')
  );


reportWebVitals();
