import React from 'react';
import Header from "../components/Header";
import Layout from './../components/gildistimi/Layout';
import Error from './../components/gildistimi/Error';
import locale from './../lang/gildistimi-locale.json';
import ReactGA from 'react-ga';
import './Gildistimi.css';

class App extends React.Component {
    constructor(props) {
        super(props);
        if(props.state!=null){
            this.state = props.state;
        }else{
            this.state = {
                data: [],
                validToken: true,
                id: window.location.search,
                token: new URLSearchParams(window.location.search).get('id'),
                isPayer: new URLSearchParams(window.location.search).get('cs'),
            };
    }}

    componentDidMount() {
        document.title = "Gildistími"; 
        this.initializeReactGA();
        if ( this.state.data.length === 0 ){
            fetch(process.env.REACT_APP_BACKEND_ROUTE.concat("gildistimi/getCardInfo/") + this.state.token)
            .then(response => response.json())
              .then((data) => {
                  this.setState({
                      data: data,
                      validToken: data.GetInvoiceOutput.StandardOutput.success
                  });
              }).catch((error) => {
                this.setState({validToken: false});
              });
        
        }
        }

    initializeReactGA = () => {

        try{
            ReactGA.initialize('UA-20106629-1');
        }
        catch(error){
            ReactGA.initialize('UA-20106629-1' , { testMode:true });
        }
        ReactGA.pageview('/gildistimi');
    };

    render() {
        const { data, validToken, token } = this.state;
        return (
            <div>
                <Header/>
                {validToken ?
                    <div className="contentwrapper">
                        {data.length === 0 ?
                            ''
                            :
                            <div className="layoutwrapper">
                                <Layout data={data} token={token} locale={locale}/>
                            </div>
                        }
                    </div>
                    :
                    <div className="contentwrapper">
                        <div className="layoutwrapper">
                            <Error data={data} locale={locale}/>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default App;