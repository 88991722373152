import React from 'react';
import { Grid, GridItem, Box, Text, useBreakpointValue, Divider, HStack } from '@chakra-ui/react'; // Import relevant components
import PropTypes from 'prop-types';

const PricesOffer = ({ data }) => {
    PricesOffer.propTypes = {
        data: PropTypes.shape({
            creationDate: PropTypes.string.isRequired,
            expirationDate: PropTypes.string.isRequired,
            todayPrice: PropTypes.number.isRequired,
            promotionalPrice: PropTypes.number.isRequired,
            afterPrice: PropTypes.number.isRequired
        }),
    };

    const isMobile = useBreakpointValue({ base: true, md: false });

    return (
        <React.Fragment>
            <Grid templateColumns={`repeat(${isMobile ? 4 : 12}, 1fr)`} gap={4} p={5} width="100%">
                <GridItem colStart={isMobile? 1 : 2} colSpan={isMobile ? 4 : 10}>
                    <Box mb="16px">
                        <HStack>
                        <Text variant="expireDateText" mb={0}>Tilboð skráð</Text><Text variant="expireDate" mb={0}> {new Date(Date.parse(data.creationDate)).toLocaleDateString(('de-DE'))}</Text><Divider orientation="vertical" height="16px" width="1px" borderColor="gray.300" /><Text variant="expireDateText" mb={0}> Tilboð rennur út</Text><Text variant="expireDate" mb={0}> {new Date(Date.parse(data.expirationDate)).toLocaleDateString(('de-DE'))}</Text>
                        </HStack>
                    </Box>
                    <Box bg="#F4F7FD" borderRadius="md" p={4} color="black" display="grid">
                        <GridItem colStart={isMobile ? 1 : 2} colSpan={isMobile ? 4 : 2}>
                            <Text variant="offerPricesHeadersForAllPrices" mb="4px">Mánaðarverð fyrir</Text>
                            <Text variant="offerPrices">{Math.round(Math.abs(Number(data.todayPrice))).toLocaleString('de-DE')} kr.</Text>
                        </GridItem>
                        <GridItem colStart={isMobile ? 1 : 5} colSpan={isMobile ? 4 : 2}>
                            <Text variant="offerPricesHeadersForAllPrices" mb="4px">Kynningarverð</Text>
                            <Text variant="offerPrices">{Math.round(Math.abs(Number(data.promotionalPrice))).toLocaleString('de-DE')} kr.</Text>
                        </GridItem>
                        <GridItem colStart={isMobile ? 1 : 8} colSpan={isMobile ? 4 : 2}>
                            <Text variant="offerPricesHeadersForAllPrices" mb="4px">Mánaðarverð eftir</Text>
                            <Text variant="offerPrices">{Math.round(Math.abs(Number(data.afterPrice))).toLocaleString('de-DE')} kr.</Text>
                        </GridItem>
                    </Box>
                </GridItem>
            </Grid>
        </React.Fragment>
    );
};
export default PricesOffer;