import React from 'react';
import { Grid, GridItem, useBreakpointValue } from '@chakra-ui/react'; // Import relevant components
import PropTypes from 'prop-types';


const AcceptOrRejectOffer = ({ acceptQuoteOrReject, acceptButton, rejectButton }) => {

    AcceptOrRejectOffer.propTypes = {
        data: PropTypes.shape({
            acceptQuoteOrReject: PropTypes.any.isRequired
        }),
    };

    const isMobile = useBreakpointValue({ base: true, md: false });

    return (
        <React.Fragment>
            <Grid templateColumns={`repeat(${isMobile ? 4 : 12}, 1fr)`} gap={4} p={5} width="100%">
                <GridItem colStart={isMobile ? 1 : 2} colSpan={isMobile ? 4 : 7}>
                    {acceptQuoteOrReject} 
                </GridItem>
                <GridItem colStart={isMobile ? 1 : 2} colSpan={isMobile ? 4 : 5}>
                    {acceptButton} 
                </GridItem>
                <GridItem colStart={isMobile ? 1 : 7} colSpan={isMobile ? 4 : 5}>
                    {rejectButton} 
                </GridItem>
            </Grid>
        </React.Fragment>
    );
};

export default AcceptOrRejectOffer;