import {Route, Routes} from 'react-router-dom';
import './App.css';
import './utils/ignoreWarningMessages';
import Utgardur from './pages/Utgardur';
import Tilbod from './pages/Tilbod';
import Gildistimi from './pages/Gildistimi';
import React from 'react';
import Redirect from "./pages/Redirect";
import ErrorBoundary from "./ErrorBoundary";
import { ChakraProvider } from '@chakra-ui/react';
import customTheme from "./themes/customTheme";

function App() {
    return (
        <div>
            <Routes>
                <Route path="*" element={<Redirect to="/"/>}/>
                <Route path="/breyta" element={<Utgardur/>}/>
                <Route path="/tilbod" element={<ErrorBoundary>
                    <ChakraProvider theme={customTheme}>
                        <Tilbod/>
                    </ChakraProvider>
                </ErrorBoundary>}/>
                <Route path="/gildistimi" element={<Gildistimi/>}/>
            </Routes>
        </div>
    );
}

export default App;