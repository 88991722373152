export const getCustomAttributeValue = (attributes, customAttribute) => {
    let attribute = attributes.find(x => x.name === customAttribute);
    return attribute != null ? attribute.value : ""
}

export const endsWithXAndNumber = (str) => {
    return /\s*x\d+$/.test(str);
}

export const calculateOriginalPrice = (product) => {
    let originalPrice = 0;
    if (product.totalPrice !== "") {
        originalPrice = Number(product.totalPrice);
    }

    return originalPrice;
}

export const calculateTotalPrice = (product, isOwner, CPQv2) => {
    let totalPrice = 0;
    let priceProduct = "";
    let showOriginalPrice = false;

    if (product.attributes && product.attributes !== "") {
        priceProduct = getCustomAttributeValue(JSON.parse(product.attributes), "PriceBeforeChargeSplit");
    }
    if (product.discounts && product.discounts.length > 0 && CPQv2) {
        showOriginalPrice = true;
    }

    let price = product.isPayedByPayer === 'true' ?
        isOwner ? "0.0" : product.listTotal !== "0.0" ? product.listTotal : priceProduct === "" ? product.totalPrice : priceProduct
        :
        showOriginalPrice && product.originalPrice ? product.originalPrice : product.subTotal;

    if (price !== "0.0") {
        totalPrice = Number(price);
    }

    return totalPrice;

}

export const getTheSumUpOfOriginalPrice = (product) => {
    let totalOriginalPrice = calculateOriginalPrice(product);
    if (product.products && product.products.length > 0) {
        product.products.forEach((subProduct) => {
            totalOriginalPrice += getTheSumUpOfOriginalPrice(subProduct);
        });
    }
    return totalOriginalPrice;
}

export const getTheSumUpOfTotalPrice = (product, isOwner, CPQv2) => {
    let totalPrice = calculateTotalPrice(product, isOwner, CPQv2);
    if (product.products && product.products.length > 0) {
        product.products.forEach((subProduct) => {
            totalPrice += getTheSumUpOfTotalPrice(subProduct, isOwner, CPQv2);
        });
    }
    return totalPrice;
}

export const checkIfServiceNumberExistsAndHas7Digits = (product) => {
    let showNumberInProductName = false;
    if (product.serviceIdentifier && product.serviceIdentifier.length > 0) {
        const regex = /^\d{7}$/;
        showNumberInProductName = regex.test(product.serviceIdentifier);
    }
    return showNumberInProductName ? product.name + " - " + product.serviceIdentifier : product.name;
}

/**
 * Recursively filters products based on multiple conditions.
 * @param {Array} products - The array of products to filter.
 * @param {Array} conditions - An array of functions that take a product and return a boolean.
 * @returns {Array} - The filtered array of products.
 */
export const filterProducts = (products, conditions) => {
    return products.flatMap(product => {
        // Recursively filter sub-products
        if (product.products) {
            const filteredSubProducts = filterProducts(product.products, conditions);

            // If the product meets all conditions, keep it (with filtered sub-products)
            if (conditions.every(condition => condition(product))) {
                return [{ ...product, products: filteredSubProducts }];
            }

            // If the product doesn't meet the conditions but has sub-products that do, return the sub-products
            if (filteredSubProducts.length > 0) {
                return filteredSubProducts;
            }

            // If the product and its sub-products don't meet the conditions, exclude it
            return [];
        }

        // If the product has no sub-products, apply the conditions directly
        return conditions.every(condition => condition(product)) ? [product] : [];
    });
};

export const displayDiscountOrNot = (product) => {
    let showDiscount1 = product.discounts && product.discounts.length > 0 && product.discounts.some(discount => discount.discountDuration !== "4.0");
    let showDiscount2 = product.discounts && product.discounts.length > 0 && product.discounts.some(discount => discount.discountDuration === "4.0");

    if (showDiscount1) {
        return "*";
    } else if (showDiscount2) {
        return "**";
    } else {
        return "";
    }
}

/**
 * Recursively checks if any sub-products of a given product have a discount meeting any of the specified conditions.
 * @param {Object} product - The product object to check.
 * @param {Function} discountCondition1 - A function that takes a discount and returns a boolean for the first condition.
 * @param {Function} discountCondition2 - A function that takes a discount and returns a boolean for the second condition.
 * @returns {string} - Returns '*' if any sub-product meets the first condition, '**' if any sub-product meets the second condition, otherwise an empty string.
 */
export const checkDiscountInChildren = (product, discountCondition1, discountCondition2) => {
    if (product.discounts) {
        if (product.discounts.some(discountCondition1)) {
            return '*';
        }
        if (product.discounts.some(discountCondition2)) {
            return '**';
        }
    }
    if (product.products && product.products.length > 0) {
        for (const subProduct of product.products) {
            const result = checkDiscountInChildren(subProduct, discountCondition1, discountCondition2);
            if (result) {
                return result;
            }
        }
    }
    return '';
};