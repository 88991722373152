import React from 'react';
import PropTypes from "prop-types";
import './Error.css';

class Error extends React.Component {
    static propTypes = {
        locale: PropTypes.objectOf(PropTypes.object).isRequired,
        data: PropTypes.objectOf(PropTypes.object).isRequired,
    };

    render() {
        const { locale, data } = this.props;
        return (
            <div className="nuverandaskriftwrap">
                {data.GetInvoiceOutput.StandardOutput.message === "Síðan er útrunnin!" ?
                    <div>
                        <h1 className="heading">{locale.errorMessage.headingNotFoundOrOutdated}</h1>
                        <div className="textiError">
                            {locale.errorMessage.messageNotFoundOrOutdated}
                        </div>
                        <div className="textiError2">
                            Hafðu samband í Netspjalli á <a href="https://www.siminn.is/" target="_blank" className="insertlink" rel="noopener noreferrer">siminn.is</a> eða í síma 550 6000 til að uppfæra gildistímann og fá frekari upplýsingar.
                        </div>
                        <div className="textiError2">
                            {locale.errorMessage.messageHelp} <a href="https://thjonustuvefur-m.siminn.is/sso/innskraning" target="_blank" className="insertlink" rel="noopener noreferrer">Þjónustuvef Símans</a>
                        </div>
                        <div className="textiError">
                            {locale.errorMessage.messageBye}
                        </div>
                    </div>
                    :
                    <div>
                        <h1 className="heading">{locale.errorMessage.headingError}</h1>
                        <div className="textiError">
                            {locale.errorMessage.messageError}
                        </div>
                        <div className="textiError2">
                            Vinsamlegast reyndu aftur síðar. Ef vandamálið er viðvarandi vinsamlegast hafðu samband í Netspjalli á <a href="https://www.siminn.is/" target="_blank" className="insertlink" rel="noopener noreferrer">siminn.is</a> eða í síma 550 6000.
                        </div>
                        <div className="textiError">
                            {locale.errorMessage.messageBye}
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default (Error);